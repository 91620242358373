import React, { useState, useEffect, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './styles'
import SaveIcon from '@material-ui/icons/Save'
import { apiPut } from '../../../actions'
import { useSnackbar } from 'material-ui-snackbar-provider'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

interface Props {
  properties: TrailerProperties | RideProperties
  customerName: string | null
  deviceId: string
}

const CustomFields: React.FC<Props> = ({
  properties,
  customerName,
  deviceId
}) => {
  const classes = useStyles()
  const [isSavingCustomField, setIsSavingCustomField] = useState(false)
  const snackbar = useSnackbar()
  let propertiesObject: Record<string, any> = properties

  const handleOnSaveCustomField = async (customFieldName: string) => {
    const customFieldValue = (document.getElementById(
      `custom-field-${customFieldName}`
    ) as HTMLInputElement).value

    if (customFieldValue !== propertiesObject[customFieldName]) {
      setIsSavingCustomField(true)

      try {
        await apiPut<DeviceResponse>(`devices/${deviceId}`, {
          properties: {
            [customFieldName]: customFieldValue
          }
        })
        snackbar.showMessage(`Custom field ${customFieldName} updated.`)
      } catch (error) {
        snackbar.showMessage(`Error: ${error.message}`)
      }

      setIsSavingCustomField(false)
    }
  }

  const filteredProperties = useMemo(() => {
    return Object.fromEntries(
      Object.entries(properties)
        .filter(([k, v]) => {
          return typeof v === 'string' || typeof v === 'number'
        })
        .sort((a, b) => {
          if (a[0].toLowerCase() < b[0].toLowerCase()) return -1
          if (a[0].toLowerCase() > b[0].toLowerCase()) return 1
          return 0
        })
    )
  }, [properties])

  useEffect(() => {
    if (customerName) {
      propertiesObject = {
        ...propertiesObject,
        [customerName]: customerName
      }
    }
  }, [customerName])

  return (
    <Paper square className={classes.paper}>
      <Typography variant="h6" className={classes.paperHeader}>
        <Box
          component="p"
          m={0}
          fontSize="h6.fontSize"
          fontWeight="fontWeightLight"
        >
          CUSTOM FIELDS
        </Box>
      </Typography>
      {Object.keys(filteredProperties).map((key: string) => (
        <FormControl
          className={classes.customField}
          key={`custom-field-${key}`}
          variant="outlined"
        >
          <InputLabel>{key}</InputLabel>
          <OutlinedInput
            id={`custom-field-${key}`}
            type="text"
            labelWidth={100}
            defaultValue={String(propertiesObject[key])}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleOnSaveCustomField(key)}
                  disabled={isSavingCustomField}
                >
                  <SaveIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      ))}
    </Paper>
  )
}

export default CustomFields
