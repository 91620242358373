import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import Tooltip from '@material-ui/core/Tooltip'
import GroupsIcon from '@material-ui/icons/AccountTreeOutlined'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'

import CustomToolbar from '../../components/CustomToolbar'
import Modal from '../../components/Modal'
import GroupsList from './GroupsList'
import CreateGroup from './CreateGroup'
import { useStyles } from './styles'

const Groups: React.FunctionComponent = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const classes = useStyles()

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <CustomToolbar header="Groups" icon={<GroupsIcon />}>
        <Tooltip title="Add new group">
          <IconButton
            color="primary"
            aria-label="add new device"
            onClick={handleOpenModal}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </CustomToolbar>

      <Container className={classes.container}>
        <GroupsList />
      </Container>

      <Modal
        title="CREATE NEW GROUP"
        open={openModal}
        onClose={handleCloseModal}
      >
        <CreateGroup onClose={handleCloseModal} />
      </Modal>
    </>
  )
}

export default Groups
