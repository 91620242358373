import { apiGet } from '../../../actions'

interface QueryParams {
  id: string
  from: string
  to: string
}

export const getLogs = async ({ id, from, to }: QueryParams) => {
  const { logs } = await apiGet<LogsResponse>(
    `logs?deviceId=${id}&from=${from}&to=${to}&pageSize=1000&page=1`
  )

  return logs
}
