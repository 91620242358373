import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { apiGet } from '../../../actions'
import Error from '../../../components/Error'
import { useStyles } from './styles'

interface Props {
  loading: boolean
  creationError: string
  name?: string
  role?: string
  parentId?: string | null
  onClose: () => void
  onSubmit: (
    name: string,
    parentId: string | null,
    role?: string
  ) => Promise<void>
}

interface State {
  groups: Group[]
  error: string
  name: string
  role: string
  parent: Group | null
  parentName: string
}

const CreateForm: React.FunctionComponent<Props> = props => {
  const [state, setState] = useState<State>({
    groups: [],
    error: '',
    name: props.name || '',
    role: props.role || 'rever',
    parent: null,
    parentName: ''
  })
  const { groups, error, name, role, parent, parentName } = state
  const classes = useStyles()
  const isEdit = !!props.name

  const getGroups = async (role: string) => {
    const { groups } = await apiGet<GroupsResponse>(
      `groups?role=${role}&pageSize=10000&page=1`
    )
    return groups.results
  }

  useEffect(() => {
    const load = async () => {
      try {
        const realRole = role === 'fleet' ? 'fleet' : 'rever'
        const groups = await getGroups(realRole)
        setState(prev => ({
          ...prev,
          groups,
          parent: groups.find(group => group.id === props.parentId) ?? null
        }))
      } catch (err) {
        setState(prev => ({
          ...prev,
          error: err.message
        }))
      }
    }
    load()
  }, [role])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setState(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setState(prev => ({
      ...prev,
      role: value
    }))
  }

  const handleParentChange = (
    e: React.ChangeEvent<{}>,
    newParentGroup: Group | null
  ) => {
    setState(prev => ({
      ...prev,
      parent: newParentGroup
    }))
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    props.onSubmit(name, parent ? parent.id : null, role)
  }

  if (error || props.creationError) {
    return <Error legend={error || props.creationError} />
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Group Type</FormLabel>
        <RadioGroup
          className={classes.radio}
          aria-label="role"
          name="role"
          value={role}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="fleet"
            control={<Radio color="primary" disabled={isEdit} />}
            label="Fleet"
          />
          <FormControlLabel
            value="rever"
            control={<Radio color="primary" disabled={isEdit} />}
            label="Rever"
          />
          <FormControlLabel
            value="device"
            control={<Radio color="primary" disabled={isEdit} />}
            label="Device"
          />
        </RadioGroup>
      </FormControl>
      <TextField
        id="name"
        name="name"
        label="Group Name"
        variant="outlined"
        value={name}
        className={classes.input}
        onChange={handleInputChange}
        required
      />

      <Autocomplete
        id="group"
        className={classes.input}
        options={groups}
        getOptionLabel={option => option.name}
        value={parent}
        onChange={handleParentChange}
        inputValue={parentName}
        onInputChange={(e, parentName: string) =>
          setState(prev => ({
            ...prev,
            parentName
          }))
        }
        noOptionsText="No groups found."
        renderInput={params => (
          <TextField
            {...params}
            label="Choose a parent group"
            name="group"
            variant="outlined"
            className={classes.fullWidth}
          />
        )}
      />

      <div className={classes.buttonPanel}>
        <Button onClick={props.onClose} style={{ marginRight: '2rem' }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={props.loading}
        >
          {!isEdit && `Creat${props.loading ? 'ing' : 'e'}`}
          {isEdit && `Updat${props.loading ? 'ing' : 'e'}`}
        </Button>
      </div>
    </form>
  )
}

export default CreateForm
