import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh'
  },
  logo: {
    width: '80%',
    height: 'auto'
  },
  container: {
    margin: theme.spacing(0, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  formActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  spinner: { margin: theme.spacing(2) },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  alert: {
    margin: theme.spacing(3, 0)
  },
  title: {
    margin: theme.spacing(2, 0)
  }
}))
