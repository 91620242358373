import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  grid: {
    height: '100vh',
    width: '100vw'
  },
  header: {
    marginBottom: '4rem',
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  logo: {
    width: '40vw',
    height: 'auto',
    marginBottom: '2rem'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  alert: {
    margin: theme.spacing(3, 0),
    maxWidth: '50vw'
  }
}))
