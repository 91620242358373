import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    boxShadow: 'none'
  },
  cardContent: {
    padding: theme.spacing(0, 2, 2, 2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2, 0, 2)
  },
  icon: {
    marginLeft: theme.spacing(2)
  },
  cardActions: {
    margin: theme.spacing(2, 1, 0, 1),
    justifyContent: 'flex-end'
  },
  menuItem: {
    padding: 0
  }
}))
