import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: '100%'
  },
  paperHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  center: {
    marginBottom: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30vh'
  },
  groupIcon: {
    fontSize: theme.spacing(8),
    marginBottom: theme.spacing(3)
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
  grid: {
    height: '100%'
  },
  container: {
    padding: theme.spacing(2)
  },
  noLocationMessage: {
    textAlign: 'center',
    transform: 'translateY(20vh)'
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  group: {
    margin: theme.spacing(5, 0)
  },
  groupLink: {
    textAlign: 'center'
  },
  customField: {
    marginBottom: theme.spacing(3)
  },
  timeframeSelector: {
    flexBasis: '30%',
    minWidth: theme.spacing(20)
  }
}))
