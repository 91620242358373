import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MenuItem from '@material-ui/core/MenuItem'
import { useStyles } from './styles'

interface Props {
  field: Record<string, any>
  changeEvent: (e: React.ChangeEvent<HTMLInputElement>) => void
  editMode: boolean
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface Option {
  id: string
  values: string
  name: string
}

const FormSelectSearch: React.FunctionComponent<Props> = props => {
  const { readOnly, options, searching } = props.field[1]
  const name = props.field[0]
  const classes = useStyles()

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    setTimeout(() => {
      props.onSearch(e)
    }, 650)
  }

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    e.persist()
    props.changeEvent(e)
  }

  return (
    <Autocomplete
      options={options || []}
      getOptionLabel={(option: Group) => option.name}
      onChange={handleOnChange}
      disabled={readOnly}
      loading={searching}
      renderOption={option => (
        <MenuItem
          key={option.id}
          data-value={option.id}
          data-key={name}
          component="div"
          className={classes.menuItem}
        >
          {option.name}
        </MenuItem>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Group"
          placeholder="Select group"
          margin="normal"
          fullWidth
          onChange={handleSearch}
        />
      )}
    />
  )
}

export default FormSelectSearch
