import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

interface Props {
  icon: any
  dialogLegend: string
  action?: any
  tooltip: string
}

const ActionButton: React.FunctionComponent<Props> = props => {
  const { icon, action, dialogLegend, tooltip } = props
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleDisagree = () => {
    setOpen(false)
  }

  const handleAgree = () => {
    action()
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton
          color="primary"
          aria-label="add to shopping cart"
          onClick={handleClickOpen}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleDisagree}
        aria-labelledby="Delete"
        aria-describedby="Delete user"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-delete-user">
            {dialogLegend}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary">
            Disagree
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ActionButton
