import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import DetailsField from '../../../components/DetailsField'
import { useStyles } from './styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'

interface Props {
  device: DeviceData
}

const Details: React.FunctionComponent<Props> = ({ device }) => {
  const classes = useStyles()

  return (
    <Paper square className={classes.paper}>
      <Typography variant="h6" className={classes.paperHeader}>
        <Box
          component="p"
          m={0}
          fontSize="h6.fontSize"
          fontWeight="fontWeightLight"
        >
          DETAILS
        </Box>
      </Typography>
      <DetailsField label="Firmware Version" value={device.firmwareVersion} />
      <DetailsField
        label="Created"
        value={moment(device.createdAt).fromNow()}
      />
      <DetailsField
        label="Modified"
        value={moment(device.updatedAt).fromNow()}
      />
      <DetailsField
        label="Latitude & Longitude"
        value={
          device.latitude && device.longitude
            ? `${device.latitude}, ${device.longitude}`
            : '--'
        }
      />
      <DetailsField label="Serial Number" value={device.serialNumber} />
      <DetailsField label="Device ID" value={device.id} />
      {device.deviceType === 'motorcycle' && (
        <DetailsField
          label="Alert settings"
          value={
            <>
              <div style={{ marginBottom: '16px' }}>
                Master alert: {device.properties.Alerts.enabled ? 'On' : 'Off'}
              </div>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">SMS</TableCell>
                      <TableCell align="right">Push</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Movement/Ignition
                      </TableCell>
                      <TableCell align="right">
                        {device.properties.Alerts.notifications.ignition.includes(
                          'sms'
                        )
                          ? 'On'
                          : 'Off'}
                      </TableCell>
                      <TableCell align="right">
                        {device.properties.Alerts.notifications.ignition.includes(
                          'push'
                        )
                          ? 'On'
                          : 'Off'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Shock
                      </TableCell>
                      <TableCell align="right">
                        {device.properties.Alerts.notifications.shock.includes(
                          'sms'
                        )
                          ? 'On'
                          : 'Off'}
                      </TableCell>
                      <TableCell align="right">
                        {device.properties.Alerts.notifications.shock.includes(
                          'push'
                        )
                          ? 'On'
                          : 'Off'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Geofence
                      </TableCell>
                      <TableCell align="right">
                        {device.properties.Alerts.notifications.geofence.includes(
                          'sms'
                        )
                          ? 'On'
                          : 'Off'}
                      </TableCell>
                      <TableCell align="right">
                        {device.properties.Alerts.notifications.geofence.includes(
                          'push'
                        )
                          ? 'On'
                          : 'Off'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }
        />
      )}
    </Paper>
  )
}

export default Details
