import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core'

interface Props {
  legend?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    margin: theme.spacing(0, 2)
  },
  legend: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))
export const NotFound: React.FunctionComponent<Props> = props => {
  const { legend } = props
  const classes = useStyles()
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <Grid container justify="center" alignItems="center">
        <Typography color="primary" variant="h2">
          404
        </Typography>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Typography variant="h5" className={classes.legend}>
          {legend || 'This page could not be found.'}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default NotFound
