import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import { useStyles } from '../styles'
import Container from '@material-ui/core/Container'
import Error from '../../../components/Error'
import Spinner from '../../../components/Spinner'
import EndUsersOverview from './Overview'
import EndUsersProperties from './Properties'
import { apiGet, apiDelete, apiPut } from '../../../actions'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'material-ui-snackbar-provider'
import CustomToolbar from '../../../components/CustomToolbar'
import ActionButton from '../../../components/ActionButton'
import DeleteIcon from '@material-ui/icons/Delete'
import PersonIcon from '@material-ui/icons/Person'
import { formatUserRole } from '../utils'

const UsersDetails: React.FunctionComponent = () => {
  const { id } = useParams()
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isSavingOverview, setIsSavingOverview] = useState<boolean>(false)
  const [isSavingProperties, setIsSavingProperties] = useState<boolean>(false)
  const [isError, setIsError] = useState<string | null>(null)

  const handleDeleteUser = async () => {
    if (user) {
      setIsDeleting(true)
      try {
        await apiDelete<UsersResponse>(`users/${user.id}`)
        snackbar.showMessage('User deleted.')
        history.push('/users')
      } catch (error) {
        setIsError(error.message)
        snackbar.showMessage(error)
      }
      setIsDeleting(false)
    }
  }

  const handleOnSaveOverview = async (
    updatedUser: Partial<User>,
    callback: () => void
  ) => {
    if (user) {
      setIsSavingOverview(true)

      try {
        await apiPut<UserResponse>(`users/${user.id}`, updatedUser)
        snackbar.showMessage('User updated.')
        callback()
        setUser({
          ...user,
          ...updatedUser
        })
      } catch (error) {
        setIsError(error.message)
      }

      setIsSavingOverview(false)
    }
  }

  const handleOnSaveProperties = async (
    properties: Partial<UserProperties>,
    callback: () => void
  ) => {
    if (user) {
      setIsSavingProperties(true)

      try {
        await apiPut<UserResponse>(`users/${user.id}`, { properties })
        snackbar.showMessage('User updated.')
        callback()
      } catch (error) {
        setIsError(error.message)
      }

      setIsSavingProperties(false)
    }
  }

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true)

      try {
        const { user } = await apiGet<UserResponse>(`users/${id}`)
        setUser(user)
      } catch (error) {
        setIsError(error.message)
      }
      setIsLoading(false)
    }
    fetchUser()
  }, [])

  if (isLoading || isDeleting) {
    return <Spinner legend={`${isLoading ? 'Loading' : 'Deleting'} user...`} />
  }

  if (isError) {
    return (
      <Error legend={`${isError}. Please try again with a different user.`} />
    )
  }

  if (!user) {
    return <Error legend={`Error fetching user. Please try again.`} />
  }

  return (
    <>
      <CustomToolbar
        header={user.name || '-'}
        caption={formatUserRole(user.role)}
        icon={<PersonIcon />}
      >
        <ActionButton
          icon={<DeleteIcon />}
          tooltip="This action will delete the current user"
          dialogLegend={`Are you sure you want to delete user ${user.name ||
            user.id}?`}
          action={handleDeleteUser}
        />
      </CustomToolbar>
      <Container className={classes.main}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} md={6}>
            <EndUsersOverview
              user={user}
              onSave={handleOnSaveOverview}
              saving={isSavingOverview}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EndUsersProperties
              user={user}
              onSave={handleOnSaveProperties}
              saving={isSavingProperties}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default UsersDetails
