import React from 'react'
import { useStyles } from './styles'
import { startCase } from 'lodash'
import TextField from '@material-ui/core/TextField'

interface Props {
  field: Record<string, any>
  changeEvent: (e: React.ChangeEvent<HTMLInputElement>) => void
  editMode: boolean
}

const FormTextField: React.FunctionComponent<Props> = props => {
  const classes = useStyles()
  const { defaultValue, readOnly, htmlType } = props.field[1]
  const name = props.field[0]

  return (
    <TextField
      key={name}
      variant="outlined"
      className={classes.textField}
      type={htmlType || 'text'}
      label={`${startCase(name)}`}
      name={`${name}`}
      defaultValue={defaultValue || ''}
      onChange={props.changeEvent}
      disabled={readOnly ? readOnly : !props.editMode}
      fullWidth={true}
    />
  )
}

export default FormTextField
