import React from 'react'
import { Link } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import { useStyles } from '../styles'

interface Props {
  devices: DeviceData[]
}

const DeviceGrid: React.FunctionComponent<Props> = ({ devices }) => {
  const classes = useStyles()

  return (
    <Paper square>
      <div className={classes.devicesTitle}>
        <Typography>
          <strong>SERIAL NUMBER</strong>
        </Typography>
        <Typography>
          <strong>DEVICE ID</strong>
        </Typography>
        <Typography>
          <strong>STATUS</strong>
        </Typography>
        <Typography>
          <strong>CREATED</strong>
        </Typography>
      </div>

      {devices.map(device => (
        <div key={device.id} className={classes.devicesGrid}>
          <Link to={`/devices/${device.id}`}>
            <Typography>{device.serialNumber}</Typography>
          </Link>

          <Typography>{device.id}</Typography>
          <Typography>{device.connected ? 'ONLINE' : 'OFFLINE'}</Typography>
          <Typography>{moment(device.createdAt).fromNow()}</Typography>
        </div>
      ))}
    </Paper>
  )
}

export default DeviceGrid
