import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import Table from '../../../components/Table'
import { getDevices, searchDevices } from './utils'

interface State {
  loading: boolean
  count: number
  data: (string | number)[][]
  error: string
}

const columns: Column[] = [
  {
    name: 'SERIAL NUMBER',
    dbColumn: 'serial',
    filter: true,
    filterType: 'textField'
  },
  {
    name: 'DEVICE ID',
    dbColumn: 'id',
    filter: true,
    filterType: 'textField'
  },
  {
    name: 'CREATED',
    dbColumn: 'created_at',
    filter: false
  },
  {
    name: 'MODIFIED',
    dbColumn: 'updated_at',
    filter: false
  },
  {
    name: 'STATUS',
    dbColumn: 'connected',
    filter: true,
    filterType: 'dropdown',
    filterOptions: { names: ['ONLINE', 'OFFLINE'] }
  },
  {
    name: 'ACTIVATION CODE',
    dbColumn: 'ActivationCode',
    filter: true,
    filterType: 'textField',
    isProperty: true,
    display: 'excluded'
  },
  {
    name: 'SIM',
    dbColumn: 'ICCID',
    filter: true,
    filterType: 'textField',
    isProperty: true,
    display: 'excluded'
  },
  {
    name: 'CUSTOMER NAME',
    dbColumn: 'name',
    filter: true,
    filterType: 'textField',
    isProperty: true,
    display: 'excluded'
  },
  {
    name: 'CUSTOMER EMAIL',
    dbColumn: 'EmailAddress',
    filter: true,
    filterType: 'textField',
    isProperty: true,
    display: 'excluded'
  }
]

const DevicesList: React.FunctionComponent = () => {
  const [state, setState] = useState<State>({
    loading: false,
    count: 0,
    data: [],
    error: ''
  })
  const { loading, count, data, error } = state
  const history = useHistory()

  const onRowClick = (rowData: any) => {
    const _id = rowData[1]
    history.push(`/devices/${_id}`)
  }

  const fetchDevices = async ({
    page,
    pageSize,
    sortBy,
    sortOrder,
    filters
  }: FetchOptions) => {
    setState(prev => ({ ...prev, loading: true }))

    const { results, meta } = await getDevices({
      page,
      pageSize,
      sortBy,
      sortOrder,
      filters
    })

    try {
      const data = results.map(device => [
        device.serialNumber,
        device.id,
        moment(device.createdAt).fromNow(),
        moment(device.updatedAt).fromNow(),
        device.connected ? 'ONLINE' : 'OFFLINE'
      ])

      setState(prev => ({
        ...prev,
        count: meta.count,
        loading: false,
        data
      }))
    } catch (error) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: (error as Error).toString()
      }))
    }
  }

  const search = async ({
    page,
    pageSize,
    sortBy,
    sortOrder,
    filters,
    body
  }: SearchOptions) => {
    setState(prev => ({ ...prev, loading: true }))

    const { results, meta } = await searchDevices({
      page,
      pageSize,
      sortBy,
      sortOrder,
      filters,
      body
    })

    try {
      const data = results.map(device => [
        device.serialNumber,
        device.id,
        moment(device.createdAt).fromNow(),
        moment(device.updatedAt).fromNow(),
        device.connected ? 'ONLINE' : 'OFFLINE'
      ])

      setState(prev => ({
        ...prev,
        count: meta.count,
        loading: false,
        data
      }))
    } catch (error) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: (error as Error).message
      }))
    }
  }

  return (
    <Table
      // @ts-ignore
      tableName="Devices"
      searchPlaceholder="Search by IMEI"
      searchProperty="IMEI"
      loading={loading}
      error={error}
      count={count}
      fetchData={fetchDevices}
      searchData={search}
      onRowClick={onRowClick}
      columns={columns}
      data={data}
    />
  )
}

export default DevicesList
