import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  passwordContianer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    padding: 0
  },
  password: {
    flexGrow: 2,
    textAlign: 'center',
    margin: 0
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textPanel: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  important: {
    color: theme.palette.primary.main
  },
  input: {
    width: '100%',
    paddingBottom: theme.spacing(1.5),
    margin: theme.spacing(2, 0)
  }
}))
