import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import CardActions from '@material-ui/core/CardActions'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import { useStyles } from './styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Spinner from '../Spinner'
import { renderField } from './utils'
import Box from '@material-ui/core/Box'

interface Props {
  title: string
  id?: string
  fields: Record<string, any>
  saving: boolean
  savingError: string | null
  fieldsProperties?: Record<string, any>
  onSave: (callback: () => void) => Promise<void>
  onFieldUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const buildFields = (
  fields: Record<string, any>,
  fieldsProperties: Record<string, any>
) => {
  let mergedObjects = {}

  Object.keys(fields).map((field: string) => {
    if (Object.keys(fieldsProperties).includes(field)) {
      mergedObjects = {
        ...mergedObjects,
        [field]: {
          defaultValue: fields[field],
          ...fieldsProperties[field]
        }
      }
    } else {
      mergedObjects = {
        ...mergedObjects,
        [field]: {
          defaultValue: fields[field]
        }
      }
    }
  })

  return mergedObjects
}

const FormEdition: React.FunctionComponent<Props> = props => {
  const [editMode, setEditMode] = useState(false)
  const classes = useStyles()

  const renderFields =
    props.fields && props.fieldsProperties
      ? buildFields(props.fields, props.fieldsProperties)
      : props.fields

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.onSave(() => setEditMode(false))
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom={false}>
          <Box
            component="p"
            m={0}
            fontSize="h6.fontSize"
            fontWeight="fontWeightLight"
          >
            {props.title.toUpperCase()}

            {!editMode && (
              <IconButton
                className={classes.icon}
                aria-label="edit"
                size="small"
                onClick={() => setEditMode(!editMode)}
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>
        </Typography>
      </CardContent>
      <CardContent className={classes.cardContent}>
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            {Object.entries(renderFields).map((field: Record<string, any>) =>
              renderField(field, props.onFieldUpdate, editMode)
            )}

            {editMode && (
              <CardActions className={classes.cardActions}>
                {props.savingError && (
                  <FormHelperText>{props.savingError}</FormHelperText>
                )}

                {props.saving ? (
                  <Spinner />
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="default"
                      startIcon={<DeleteIcon />}
                      onClick={() => setEditMode(!editMode)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Save
                    </Button>
                  </>
                )}
              </CardActions>
            )}
          </FormControl>
        </form>
      </CardContent>
    </Card>
  )
}

export default FormEdition
