import Button from '@material-ui/core/Button'
import React, { useState } from 'react'
import CustomToolbar from '../../../components/CustomToolbar'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import TextField from '@material-ui/core/TextField'
import { useStyles } from './styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography } from '@material-ui/core'
import { apiPut } from '../../../actions/api'
import Spinner from '../../../components/Spinner'
import { useSnackbar } from 'material-ui-snackbar-provider'

const Models: React.FC = () => {
  const classes = useStyles()

  const snackbar = useSnackbar()

  const [models, setModels] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const result = await apiPut<{ insertedCount: number }>('devices/models', {
        data: models
      })
      snackbar.showMessage(
        `Successfully inserted ${result.insertedCount} records`
      )
    } catch (err) {
      const error = err as Error
      snackbar.showMessage(`Unexpected error: ${error.message}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <CustomToolbar header="Models" icon={<MotorcycleIcon />}></CustomToolbar>
      <Container className={classes.main}>
        <Grid container justify="center" spacing={3} md={12}>
          <form
            onSubmit={e => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <Card>
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ marginBottom: '18px' }}
                >
                  Copy & paste the list of models that should be added.
                  <br />
                  Format must be CSV using commas and new lines as separators.
                  <br />
                  The first line must include the following column names: year,
                  make, model, type
                  <br />
                  Duplicate entries are automatically discarded.
                  <br />
                </Typography>
                <TextField
                  id="models"
                  name="models"
                  label="Input data"
                  variant="outlined"
                  multiline
                  rows={20}
                  placeholder="<year>,<make>,<model>,<type>"
                  onChange={e => setModels(e.target.value)}
                  required
                  value={models}
                  className={classes.input}
                />
                <div className={classes.footer}>
                  {loading && <Spinner />}
                  {!loading && (
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </CardContent>
            </Card>
          </form>
        </Grid>
      </Container>
    </div>
  )
}

export default Models
