import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginLeft: theme.spacing(2)
  },
  main: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cardActions: {
    margin: theme.spacing(2, 1),
    justifyContent: 'flex-end'
  }
}))
