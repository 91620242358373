import { apiGet, apiPost } from '../../../actions'

export const getDevices = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  filters
}: FetchOptions) => {
  const sortByString = sortBy ? `&sortBy=${sortBy}` : ''
  const sortOrderString = sortOrder ? `&sortOrder=${sortOrder}` : ''
  const filterString = filters || ''

  const { devices } = await apiGet<DevicesResponse>(
    `devices?pageSize=${pageSize}&page=${page +
      1}${sortByString}${sortOrderString}${filterString}`
  )

  return devices
}

export const searchDevices = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  filters,
  body
}: SearchOptions) => {
  const sortByString = sortBy ? `&sortBy=${sortBy}` : ''
  const sortOrderString = sortOrder ? `&sortOrder=${sortOrder}` : ''
  const filterString = filters || ''

  const { devices } = await apiPost<DevicesResponse>(
    `devices/search?pageSize=${pageSize}&page=${page +
      1}${sortByString}${sortOrderString}${filterString}`,
    body
  )

  return devices
}
