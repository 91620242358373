import React from 'react'
import { Switch, Route, Link, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import AccountTreeOutlined from '@material-ui/icons/AccountTreeOutlined'
import DevicesIcon from '@material-ui/icons/Devices'
import PersonIcon from '@material-ui/icons/Person'
import MapIcon from '@material-ui/icons/Map'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import { SnackbarProvider } from 'material-ui-snackbar-provider'

import { theme } from '../constants/theme'
import Devices from '../pages/Devices'
import Device from '../pages/Device'
import Groups from '../pages/Groups'
import Group from '../pages/Group'
import Users from '../pages/Users'
import NotFound from '../pages/NotFound'
import Home from '../pages/Home'
import AccountAdmins from '../pages/AccountAdmins'
import DevicesLocation from '../pages/Insights/DevicesLocation'
import DeviceModels from '../pages/Devices/Models'
import AppBarUserMenu from './AppBarUserMenu'
import RouteWithTitle from './RouteWithTitle'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: theme.palette.common.white
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(5),
    color: theme.palette.primary.main
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  chevron: {
    color: theme.palette.primary.main
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  listItem: {
    paddingLeft: theme.spacing(3)
  },
  active: {
    '& *': {
      color: theme.palette.primary.main
    }
  }
}))

interface DrawerMenuItem {
  label: string
  icon?: JSX.Element
  to: string
  tooltip: string
}

const drawerMenu: DrawerMenuItem[] = [
  {
    label: 'Devices',
    icon: <DevicesIcon />,
    to: '/devices',
    tooltip: 'Devices'
  },
  {
    label: 'Users',
    icon: <PersonIcon />,
    to: '/users',
    tooltip: 'Users'
  },
  {
    label: 'Groups',
    icon: <AccountTreeOutlined />,
    to: '/groups',
    tooltip: 'Groups'
  },
  {
    label: 'Devices location',
    icon: <MapIcon />,
    to: '/insight/location',
    tooltip: 'Devices location'
  },
  {
    label: 'Models',
    icon: <MotorcycleIcon />,
    to: '/models',
    tooltip: 'Models'
  }
]

const Layout: React.FunctionComponent = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  let location = useLocation()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open
              })}
            >
              <MenuIcon />
            </IconButton>
            <AppBarUserMenu />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon className={classes.chevron} />
              ) : (
                <ChevronLeftIcon className={classes.chevron} />
              )}
            </IconButton>
          </div>
          <List>
            {drawerMenu.map((item: DrawerMenuItem, index: number) => (
              <Tooltip
                title={item.label}
                aria-label={item.label}
                placement="right"
                key={`tooltip-${index}`}
              >
                <ListItem
                  button
                  key={item.label}
                  component={Link}
                  className={
                    location.pathname.includes(item.to)
                      ? clsx(classes.listItem, classes.active)
                      : classes.listItem
                  }
                  to={item.to}
                >
                  {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                  <ListItemText primary={item.label} />
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </Drawer>
        <main className={classes.main}>
          <div className={classes.toolbar} />
          <Switch>
            <RouteWithTitle
              title="Insight Location"
              path="/insight/location"
              component={DevicesLocation}
            />
            <RouteWithTitle
              title="Device details"
              path="/devices/:id"
              component={Device}
            />
            <RouteWithTitle
              title="Devices list"
              exact
              path="/devices"
              component={Devices}
            />
            <Route path="/users" component={Users} />
            <RouteWithTitle
              title="Group details"
              path="/groups/:id"
              component={Group}
            />
            <RouteWithTitle
              title="Groups list"
              path="/groups"
              component={Groups}
            />
            <RouteWithTitle
              title="Account admins"
              path="/account-admins"
              component={AccountAdmins}
            />
            <RouteWithTitle
              title="Models"
              path="/models"
              component={DeviceModels}
            />
            <RouteWithTitle title="" exact path="/" component={Home} />
            <RouteWithTitle path="*" component={NotFound} />
          </Switch>
        </main>
      </div>
    </SnackbarProvider>
  )
}

export default Layout
