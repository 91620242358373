import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

interface Props {
  legend?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    margin: theme.spacing(0, 2)
  },
  legend: {
    color: theme.palette.error.main,
    padding: theme.spacing(2)
  },
  icon: {
    marginBottom: theme.spacing(2),
    fontSize: '3rem',
    color: theme.palette.error.main
  }
}))
export const Error: React.FunctionComponent<Props> = props => {
  const { legend } = props
  const classes = useStyles()
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <Grid container justify="center" alignItems="center" direction="column">
        <ErrorIcon className={classes.icon} />
        <Typography variant="h5" className={classes.legend}>
          {legend || 'Unknown error'}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Error
