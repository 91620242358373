import React, { useState } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { useStyles } from './styles'
import { CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

interface Props {
  onPublish: (message: string) => void
  onSendSMS: (message: string) => void
  loading: boolean
}

const PublishPanel: React.FunctionComponent<Props> = props => {
  const classes = useStyles()
  const [message, setMessage] = useState<string>('')

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  return (
    <Paper square>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Typography variant="h5">PUBLISH</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <TextField
            id="standard-multiline-static"
            multiline
            minRows="3"
            variant="outlined"
            placeholder="Type message..."
            className={classes.textArea}
            onChange={handleChange}
            value={message}
          />
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions className={classes.actions}>
          <Button size="small" onClick={() => setMessage('')}>
            Clear
          </Button>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            disabled={message.length === 0 || props.loading}
            style={{ marginLeft: '8px' }}
            onClick={() => props.onPublish(message)}
          >
            Publish
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            disabled={message.length === 0 || props.loading}
            style={{ marginLeft: '8px' }}
            onClick={() => props.onSendSMS(message)}
          >
            Send SMS
          </Button>
          {props.loading && <CircularProgress size={24} color="secondary" />}
        </ExpansionPanelActions>
      </ExpansionPanel>
    </Paper>
  )
}

export default PublishPanel
