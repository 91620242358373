import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import GroupsIcon from '@material-ui/icons/AccountTreeOutlined'

import Error from '../../../components/Error'
import Spinner from '../../../components/Spinner'
import GroupsGrid from '../../../components/GroupsGrid'
import { getChildren } from '../utils'
import { useStyles } from '../styles'

interface Props {
  groupId: string
  pageSize: number
}

interface State {
  loading: boolean
  error: string
  children: Group[]
  childCount: number
}

const GroupChildren: React.FunctionComponent<Props> = ({
  groupId,
  pageSize
}) => {
  const [state, setState] = useState<State>({
    loading: true,
    error: '',
    children: [],
    childCount: 0
  })
  const { loading, error, children, childCount } = state
  const [page, setPage] = useState<number>(1)
  const [more, setMore] = useState<boolean>(false)
  const classes = useStyles()

  useEffect(() => {
    const load = async () => {
      setState(prev => ({
        ...prev,
        loading: true
      }))

      try {
        const { results, meta } = await getChildren(groupId, page)
        setState(prev => ({
          ...prev,
          loading: false,
          children: results,
          childCount: meta.count
        }))
      } catch (err) {
        setState(prev => ({
          ...prev,
          loading: false,
          error: err.message
        }))
      }
    }
    load()
  }, [groupId])

  const loadMore = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setMore(true)

    try {
      const { results, meta } = await getChildren(groupId, page + 1)
      setPage(page + 1)
      const newChildren = children.concat(results)
      setState(prev => ({
        ...prev,
        children: newChildren,
        childCount: meta.count
      }))
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: err.message
      }))
    }

    setMore(false)
  }

  if (!loading && error) {
    return <Error legend={error} />
  }

  return (
    <>
      <Grid item xs={12} className={classes.section}>
        <GroupsIcon className={classes.icon} />
        <Typography component="span">
          <Box
            component="p"
            m={0}
            fontSize="h6.fontSize"
            fontWeight="fontWeightLight"
          >
            SUB-GROUPS{' '}
            {childCount > 0 && (
              <>
                <strong>{children.length}</strong> of {childCount}
              </>
            )}
          </Box>
        </Typography>
      </Grid>

      {loading ? (
        <Spinner legend="Loading Subgroups..." />
      ) : (
        <Grid item xs={12}>
          {children.length ? (
            <GroupsGrid groups={children} />
          ) : (
            <Typography>No sub-groups found</Typography>
          )}

          {!more && page + pageSize < childCount && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.moreButton}
              onClick={loadMore}
            >
              Show more
            </Button>
          )}
          {more && (
            <LinearProgress color="primary" className={classes.moreButton} />
          )}
        </Grid>
      )}
    </>
  )
}

export default GroupChildren
