import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MUIDataTable, { debounceSearchRender } from 'mui-datatables'
import Error from '../Error'
import Container from '@material-ui/core/Container'
import { makeStyles, Theme, Box } from '@material-ui/core'
import { filtersToString, filterProperties } from './utils'
import './styles.scss'
import LinearProgress from '@material-ui/core/LinearProgress'

interface Props {
  fetchData: (data: any) => Promise<void>
  searchData: (data: any) => Promise<void>
  onRowClick: (data: any) => void
  loading: boolean
  error: string | null
  count: number
  tableName: string
  searchPlaceholder: string
  searchProperty: string
  columns: Column[]
  data: (string | number)[][]
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3)
  },
  filtersFooter: {
    marginTop: theme.spacing(3)
  }
}))

const Table: React.FunctionComponent<Props> = props => {
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(20)
  const [searchParam, setSearchParam] = useState<string>('')
  const [sortBy, setSortBy] = useState<string>('')
  const [sortOrder, setSortOrder] = useState<string>('asc')
  const [filters, setFilters] = useState<string>('')
  const [properties, setProperties] = useState<Record<string, any>>({})

  const [columnSortDirection, setColumnSortDirection] = useState<string[]>(
    () => {
      return props.columns.map(() => 'none')
    }
  )
  const [columnSortName, setColumnSortName] = useState<string>('none')
  const classes = useStyles()

  const changePage = (page: number) => setPage(page)

  const changeRowsPerPage = (pageSize: number) => setPageSize(pageSize)

  const changeSort = (sortBy: string, _sortOrder: string) => {
    const index = props.columns.findIndex(column => column.name === sortBy)
    const newColumnSortDirections = props.columns.map(() => 'none')
    newColumnSortDirections[index] = _sortOrder

    setColumnSortDirection(newColumnSortDirections)
    setSortOrder(_sortOrder)
    setSortBy(props.columns[index].dbColumn)
    setColumnSortName(sortBy)
  }

  const filterSubmit = (filterList: string[]) => {
    const filters = filtersToString(filterList, props.columns)
    const properties = filterProperties(filterList, props.columns)
    setFilters(filters)
    setProperties(properties)
  }

  const handleSearch = () => {
    return props.searchData({
      page,
      pageSize,
      sortBy,
      sortOrder,
      filters,
      body: {
        ...properties,
        ...(searchParam && { [props.searchProperty]: searchParam })
      }
    })
  }

  const handleTableChange = (action: string, tableState: any) => {
    switch (action) {
      case 'sort':
        const { name, direction } = tableState.sortOrder
        changeSort(name, direction)
        break
      default:
        break
    }
  }

  const handleSearchChange = () => {
    const searchInput = document.querySelector(
      '.MuiInput-input'
    ) as HTMLInputElement

    if (searchInput) {
      setSearchParam(searchInput.value)
    }
  }

  useEffect(() => {
    handleSearch()
  }, [page, pageSize, sortBy, sortOrder, filters, properties, searchParam])

  // Generate Table columns
  const columns = props.columns.map((column, index) => ({
    name: column.name,
    options: {
      filter: column.filter,
      filterType: column.filterType,
      ...(column.filterOptions && { filterOptions: column.filterOptions }),
      ...(column.display && { display: column.display })
    }
  }))

  // Table options, see https://github.com/gregnb/mui-datatables
  const options = {
    rowsPerPage: pageSize,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    count: props.count,
    page,
    responsive: 'standard',
    onChangePage: (currentPage: number) => changePage(currentPage),
    onChangeRowsPerPage: (numberOfRows: number) =>
      changeRowsPerPage(numberOfRows),
    onTableChange: handleTableChange,
    customFilterDialogFooter: (filterList: any[]) => (
      <Box className={classes.filtersFooter}>
        <Button variant="contained" onClick={() => filterSubmit(filterList)}>
          Apply
        </Button>
      </Box>
    ),
    onSearchClose: () => {
      setSearchParam('')
    },
    searchProps: {
      onKeyUp: (e: KeyboardEvent) => {
        if (e.keyCode === 13) {
          handleSearchChange()
        }
      }
    },
    onRowClick: (rowData: any) => props.onRowClick(rowData),
    sortOrder: {
      name: columnSortName,
      direction: sortOrder
    },
    selectableRows: 'none',
    searchPlaceholder: props.searchPlaceholder,
    searchText: searchParam,
    serverSide: true,
    elevation: 1,
    viewColumns: false,
    downloadOptions: {
      filename: `${props.tableName}.csv`
    },
    customSearchRender: debounceSearchRender(500)
  }

  if (props.error) {
    return <Error legend={props.error} />
  }

  return (
    <>
      {props.loading && <LinearProgress />}
      <Container className={classes.container}>
        <MUIDataTable
          title={
            <Typography variant="subtitle1">
              {props.tableName.toUpperCase()}{' '}
              <strong>
                {props.count > 20 ? (page + 1) * 20 : props.count}
              </strong>{' '}
              of {props.count}
            </Typography>
          }
          data={props.data}
          columns={columns}
          options={options}
        />
      </Container>
    </>
  )
}

export default Table
