import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import MessagesToolbar from './MessagesToolbar'
import MessageItem from './MessageItem'
import { useStyles } from './styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'

interface Props {
  messages: MqttMessage[]
  connected: boolean
}

const MessagesPanel: React.FunctionComponent<Props> = props => {
  const classes = useStyles()
  const [channelFilter, setChannelFilter] = useState('all channels')
  const [filteredMessages, setFilteredMessages] = useState(props.messages)

  useEffect(() => {
    channelFilter === 'all channels'
      ? setFilteredMessages(props.messages)
      : setFilteredMessages(
          props.messages.filter(message =>
            message.channel.includes(channelFilter)
          )
        )
  }, [channelFilter, props.messages])

  return (
    <>
      <MessagesToolbar
        handleChannelFilter={setChannelFilter}
        connected={props.connected}
      />
      {filteredMessages.length ? (
        filteredMessages.map((message: MqttMessage, index: number) => (
          <MessageItem message={message} key={`message_${index}`} />
        ))
      ) : (
        <ExpansionPanel className={classes.textCenter}>
          <Typography className={classes.heading} noWrap>
            No messages available
          </Typography>
        </ExpansionPanel>
      )}
    </>
  )
}

export default MessagesPanel
