import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import CardActions from '@material-ui/core/CardActions'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import { useStyles } from './styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Spinner from '../Spinner'
import { renderField } from './utils'
import Box from '@material-ui/core/Box'

interface Props {
  title?: string
  fields: Record<string, any>
  saving: boolean
  savingError: string | null
  onFieldUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSave: () => Promise<void>
}

const FormCreate: React.FunctionComponent<Props> = props => {
  const classes = useStyles()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.onSave()
  }

  return (
    <Card className={classes.card}>
      {props.title && (
        <CardContent>
          <Typography variant="h6" gutterBottom={false}>
            <Box
              component="p"
              m={0}
              fontSize="h6.fontSize"
              fontWeight="fontWeightLight"
            >
              {props.title.toUpperCase()}
            </Box>
          </Typography>
        </CardContent>
      )}
      <CardContent className={classes.cardContent}>
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            {Object.entries(props.fields).map((field: Record<string, any>) =>
              renderField(field, props.onFieldUpdate, true)
            )}

            <CardActions className={classes.cardActions}>
              {props.savingError && (
                <FormHelperText>{props.savingError}</FormHelperText>
              )}

              {props.saving ? (
                <Spinner />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  type="submit"
                >
                  Save
                </Button>
              )}
            </CardActions>
          </FormControl>
        </form>
      </CardContent>
    </Card>
  )
}

export default FormCreate
