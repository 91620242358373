import { apiGet } from '../../../actions'

interface QueryParams {
  id: string
  from: string
  to: string
  page: number
}

export interface AlertsListResponse {
  data: {
    results: Record<string, unknown>[]
    meta: Record<string, unknown>
  }
}

export const getAlerts = async ({
  id,
  from,
  to,
  page = 1
}: QueryParams): Promise<AlertsListResponse['data']> => {
  const res = await apiGet<AlertsListResponse>(
    `devices/${id}/alerts?from=${from}&to=${to}&pageSize=100&page=${page}`
  )

  return res.data
}
