import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
    flexGrow: 1,
    height: '100%'
  },
  paper: {
    height: '100%'
  }
}))
