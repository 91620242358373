import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import GroupsIcon from '@material-ui/icons/AccountTreeOutlined'
import ClearIcon from '@material-ui/icons/CloseOutlined'
import SearchIcon from '@material-ui/icons/SearchOutlined'

import Error from '../../../components/Error'
import Spinner from '../../../components/Spinner'
import GroupsGrid from '../../../components/GroupsGrid'
import { getGroups, searchGroup } from './utils'
import { useStyles } from './styles'

interface State {
  loading: boolean
  error: string
  groups: Group[]
}

const titles = {
  topLevel: 'TOP-LEVEL GROUPS',
  searchResults: 'SEARCH RESULTS'
}

const GroupsList: React.FunctionComponent = props => {
  const [state, setState] = useState<State>({
    loading: true,
    error: '',
    groups: []
  })
  const { loading, error, groups } = state
  const [title, setTitle] = useState<string>(titles.topLevel)
  const [searchText, setSearchText] = useState<string>('')
  const classes = useStyles()

  useEffect(() => {
    const load = async () => {
      setState(prev => ({
        ...prev,
        loading: true
      }))

      try {
        const groups = await getGroups()
        setState(prev => ({
          ...prev,
          loading: false,
          groups
        }))
      } catch (err) {
        setState(prev => ({
          ...prev,
          loading: false,
          error: err.message
        }))
      }
    }
    load()
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchText(value)
  }

  const onSearch = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState(prev => ({
      ...prev,
      loading: true,
      groups: []
    }))
    setTitle(titles.searchResults)

    try {
      const groups = await searchGroup(searchText)
      setState(prev => ({
        ...prev,
        loading: false,
        groups
      }))
    } catch (err) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: err.message
      }))
    }
  }

  const onClear = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSearchText('')
    setTitle(titles.topLevel)
    setState(prev => ({
      ...prev,
      loading: true,
      groups: []
    }))

    try {
      const groups = await getGroups()
      setState(prev => ({
        ...prev,
        loading: false,
        groups
      }))
    } catch (err) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: err.message
      }))
    }
  }

  if (loading) {
    return <Spinner legend="Loading Groups..." />
  }

  if (!loading && error) {
    return <Error legend={error} />
  }

  return (
    <Grid container direction="row" spacing={2} className={classes.container}>
      <Grid item xs={12} className={classes.topLevel}>
        <GroupsIcon className={classes.icon} />
        <Typography component="span">
          <Box
            component="p"
            m={0}
            fontSize="h6.fontSize"
            fontWeight="fontWeightLight"
          >
            {title} <strong>{groups.length}</strong>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper square className={classes.paper}>
          <Typography component="span">
            <Box
              component="p"
              m={0}
              fontSize="h6.fontSize"
              fontWeight="fontWeightLight"
            >
              SEARCH
            </Box>
          </Typography>
          <div className={classes.search}>
            <TextField
              id="search"
              name="searchText"
              value={searchText}
              placeholder="Enter group name"
              className={classes.searchTextField}
              onChange={handleChange}
            />
            <ButtonGroup
              size="large"
              variant="text"
              className={classes.buttonGroup}
            >
              <Button onClick={onClear}>
                <ClearIcon />
              </Button>
              <Button disabled={searchText === ''} onClick={onSearch}>
                <SearchIcon />
              </Button>
            </ButtonGroup>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <GroupsGrid groups={groups} />
      </Grid>
    </Grid>
  )
}

export default GroupsList
