import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import { apiGet } from '../../../actions'
import Details from './Details'
import CustomFields from './CustomFields'
import AssociatedUsers from './AssociatedUsers'
import Location from './Location'
import { useStyles } from './styles'
import Error from '../../../components/Error'
import Spinner from '../../../components/Spinner'

interface Props {
  loading: boolean
  error: string
  device: DeviceData | null
}

const DeviceOverview: React.FunctionComponent<Props> = props => {
  const [group, setGroup] = useState<Group | null>(null)
  const [users, setUsers] = useState<User[]>([])
  const [customerName, setCustomerName] = useState<string | null>(null)
  const { loading, error, device } = props

  const classes = useStyles()

  useEffect(() => {
    const load = async () => {
      if (device && device.groupId) {
        const { groupId } = device
        const { group } = await apiGet<GroupResponse>(`groups/${groupId}`)
        setGroup(group)
        const { users } = await apiGet<UsersResponse>(
          `users?groupId=${groupId}`
        )
        setUsers(users.results)
      }

      if (device && device.deviceType === 'motorcycle') {
        // TODO: get customer name from REVER
        setCustomerName(null)
      }
    }
    load()
  }, [])

  if (loading) {
    return <Spinner legend="Loading device..." />
  }

  if (!loading && error) {
    return <Error legend={error} />
  }

  return (
    device && (
      <Container className={classes.container}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} md={4}>
            <Details device={device} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Location device={device} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomFields
              properties={device.properties}
              customerName={customerName}
              deviceId={device.id}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <AssociatedUsers group={group} users={users} />
          </Grid>
        </Grid>
      </Container>
    )
  )
}

export default DeviceOverview
