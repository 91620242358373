import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import Modal from '../Modal'
import { useStyles } from './styles'

interface Props {
  title: string
  open: boolean
  onClose: () => void
  legend: string
}

const MessageModal: React.FunctionComponent<Props> = props => {
  const classes = useStyles()

  return (
    <Modal title={props.title} open={props.open} onClose={props.onClose}>
      <Typography variant="body1">{props.legend}</Typography>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" onClick={props.onClose}>
          Ok
        </Button>
      </div>
    </Modal>
  )
}

export default MessageModal
