import React, { useState, useEffect } from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import FormControl from '@material-ui/core/FormControl'
import { DateTimePicker } from '@material-ui/pickers'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import CheckIcon from '@material-ui/icons/Check'

import { useStyles } from './styles'
import IconButton from '@material-ui/core/IconButton'

interface Props {
  onChangeDate: (from: string, to: string) => void
}

const LogsToolbar: React.FunctionComponent<Props> = props => {
  const classes = useStyles()
  const minDate = moment().subtract(29, 'days')
  const maxDate = moment()
  const [fromDate, setFromDate] = useState<any>(minDate)
  const [toDate, setToDate] = useState<any>(maxDate)
  const [disabled, setDisabled] = useState<boolean>(false)

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    props.onChangeDate(fromDate.toISOString(), toDate.toISOString())
  }

  useEffect(() => {
    const isAfter = !moment(fromDate).isBefore(toDate)
    setDisabled(isAfter)
  }, [fromDate, toDate])

  return (
    <Toolbar className={classes.toolbar}>
      <Typography variant="h5">ACTIVITY LOG</Typography>
      <div className={classes.toolbarWrapper}>
        <FormControl className={classes.typeSelect}>
          <DateTimePicker
            id="from"
            variant="inline"
            label="From"
            value={fromDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={setFromDate}
          />
        </FormControl>
        <FormControl className={classes.typeSelect}>
          <DateTimePicker
            id="to"
            variant="inline"
            label="To"
            value={toDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={setToDate}
          />
        </FormControl>
        <IconButton color="primary" onClick={handleSubmit} disabled={disabled}>
          <CheckIcon />
        </IconButton>
      </div>
    </Toolbar>
  )
}

export default LogsToolbar
