import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'

interface Props {
  label?: string
  value?: any
  link?: string
}

const DetailsField: React.FunctionComponent<Props> = ({
  label,
  value,
  link
}) => {
  const content = value ? value : '--'

  return (
    <Typography component="div">
      <Box
        component="p"
        m={0}
        fontSize="body2.fontSize"
        fontWeight="fontWeightLight"
      >
        {label}:
      </Box>
      <Box
        component="p"
        mt={0}
        mb={1}
        fontSize="body2.fontSize"
        fontWeight="fontWeightBold"
      >
        {link ? <Link to={link}>{value}</Link> : content}
      </Box>
    </Typography>
  )
}

export default DetailsField
