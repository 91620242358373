import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import DevicesIcon from '@material-ui/icons/Devices'
import PersonIcon from '@material-ui/icons/Person'
import AccountTreeOutlined from '@material-ui/icons/AccountTreeOutlined'
import CountUp from 'react-countup'

import { getDevices, getUsers, getGroups } from './utils'
import { useStyles } from './styles'

interface Props {
  title: string
  text?: string
  children?: any
  count: number
  icon: any
}

interface State {
  devices: number
  users: number
  groups: number
}

const HomeCard: React.FunctionComponent<Props> = props => {
  const classes = useStyles()

  return (
    <Card>
      <CardActionArea>
        <CardContent className={classes.header}>
          {props.icon}
          <Typography variant="h5" component="p" className={classes.headerText}>
            {props.title}
          </Typography>
          <Typography variant="h3" component="p" className={classes.headerText}>
            <CountUp end={props.count} />
          </Typography>
        </CardContent>
        {props.text && (
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.text}
            </Typography>
          </CardContent>
        )}
      </CardActionArea>
      {props.children && (
        <CardActions className={classes.actions}>{props.children}</CardActions>
      )}
    </Card>
  )
}

const Home: React.FunctionComponent = () => {
  const [state, setState] = useState<State>({
    devices: 0,
    users: 0,
    groups: 0
  })
  const classes = useStyles()

  const fetchDevices = async () => {
    const { meta } = await getDevices()

    setState(prev => ({
      ...prev,
      devices: meta.count
    }))
  }

  const fetchUsers = async () => {
    const { meta } = await getUsers()

    setState(prev => ({
      ...prev,
      users: meta.count
    }))
  }

  const fetchGroups = async () => {
    const { meta } = await getGroups()

    setState(prev => ({
      ...prev,
      groups: meta.count
    }))
  }

  useEffect(() => {
    fetchDevices()
    fetchUsers()
    fetchGroups()
  }, [])

  return (
    <Container className={classes.main}>
      <Grid
        container
        direction="row"
        justify="space-between"
        spacing={6}
        alignItems="center"
        alignContent="flex-start"
        style={{ height: '100%' }}
      >
        <Grid item xs={12} md={4}>
          <Link to="/devices">
            <HomeCard
              title="Devices"
              count={state.devices}
              icon={<DevicesIcon className={classes.icon} />}
            />
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link to="/users">
            <HomeCard
              title="Users"
              count={state.users}
              icon={<PersonIcon className={classes.icon} />}
            />
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link to="/groups">
            <HomeCard
              title="Groups"
              count={state.groups}
              icon={<AccountTreeOutlined className={classes.icon} />}
            />
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Home
