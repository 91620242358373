import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Moment from 'react-moment'

import { useStyles } from './styles'
import JSONPretty from 'react-json-pretty'

interface Props {
  data: Record<string, unknown>
}

const ReportItem: React.FunctionComponent<Props> = props => {
  const classes = useStyles()

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        <>
          <div className={classes.column}>
            <Typography className={classes.heading} noWrap>
              <Moment format="YYYY-MM-DD HH:mm:ss">
                {props.data.timestamp as string}
              </Moment>
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.heading} noWrap>
              {props.data.data as string}
            </Typography>
          </div>
        </>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <div className={classes.detailItemFirst}>
          <Typography variant="caption" className={classes.secondaryHeading}>
            Properties
          </Typography>
          <JSONPretty data={props.data.properties}></JSONPretty>
        </div>

        <div>
          <Typography variant="caption" className={classes.secondaryHeading}>
            Location
          </Typography>
          <JSONPretty data={props.data.location}></JSONPretty>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default ReportItem
