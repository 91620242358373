import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  regenerateAction: {
    cursor: 'pointer',
    paddingTop: theme.spacing(1),
    transition: 'all 0.2s',
    '&:hover': {
      opacity: '0.75'
    }
  },
  grid: {
    padding: theme.spacing(2),
    height: '100%'
  },
  tabs: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: theme.palette.common.white
  },
  autocomplete: {
    padding: theme.spacing(1, 0)
  },
  input: {
    paddingBottom: theme.spacing(1.5)
  },
  buttonPanel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0)
  }
}))
