import React, { useState } from 'react'

import Table from '../../../components/Table'
import PeopleIcon from '@material-ui/icons/People'
import { getUserRoles } from '../../../lib/util'
import CustomToolbar from '../../../components/CustomToolbar'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Error from '../../../components/Error'
import Modal from '../../../components/Modal'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { apiGet } from '../../../actions'
import { pull } from 'lodash'
import UsersCreate from '../UsersCreate'

const columns: Column[] = [
  {
    name: 'EMAIL',
    dbColumn: 'email',
    filter: true,
    filterType: 'textField'
  },
  {
    name: 'NAME',
    dbColumn: 'name',
    filter: true,
    filterType: 'textField'
  },
  {
    name: 'CREATED',
    dbColumn: 'created_at',
    filter: false
  },
  {
    name: 'ID',
    dbColumn: 'id',
    filter: false,
    filterType: 'textField'
  }
]

const UsersList: React.FunctionComponent = () => {
  const history = useHistory()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [users, setUsers] = useState<string[][]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [count, setCount] = useState<number>(0)
  const [isError, setIsError] = useState<string | null>(null)

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  const onRowClick = (rowData: any) => {
    const _id = rowData[3]
    history.push(`/users/${_id}`)
  }

  const fetchUsers = async ({
    page,
    pageSize,
    sortBy,
    sortOrder,
    filters
  }: FetchOptions) => {
    setIsLoading(true)

    const sortByString = sortBy ? `&sortBy=${sortBy}` : ''
    const sortOrderString = sortOrder ? `&sortOrder=${sortOrder}` : ''
    const filterString = filters || ''

    const { users } = await apiGet<UsersResponse>(
      `users?pageSize=${pageSize}&page=${page +
        1}${sortByString}${sortOrderString}${filterString}`
    )

    try {
      const data = users.results.map(user => [
        user.email,
        user.name,
        moment(user.createdAt).fromNow(),
        user.id
      ])

      setUsers(data)
      setCount(users.meta.count)
    } catch (error) {
      setIsError(error)
    }

    setIsLoading(false)
  }

  const search = async ({
    page,
    pageSize,
    sortBy,
    sortOrder,
    filters,
    body: { email }
  }: SearchOptions) => {
    setIsLoading(true)

    const roles = pull(getUserRoles(), 'admin').toString()
    const sortByString = sortBy ? `&sortBy=${sortBy}` : ''
    const sortOrderString = sortOrder ? `&sortOrder=${sortOrder}` : ''
    const filterString = filters || ''
    const emailSearch =
      email !== undefined ? `email=${encodeURIComponent(email)}&` : ''

    const { users } = await apiGet<UsersResponse>(
      `users?${emailSearch}pageSize=${pageSize}&page=${page +
        1}&role=${roles}${sortByString}${sortOrderString}${filterString}`
    )

    try {
      const data = users.results.map(user => [
        user.email,
        user.name,
        moment(user.createdAt).fromNow(),
        user.id
      ])

      setUsers(data)
      setCount(users.meta.count)
    } catch (error) {
      setIsError(error)
    }

    setIsLoading(false)
  }

  if (isError) {
    return <Error legend={`${isError}.`} />
  }

  return (
    <>
      <CustomToolbar header="Users" icon={<PeopleIcon />}>
        <Tooltip title={'Add new user.'}>
          <IconButton
            color="primary"
            aria-label="add new user"
            onClick={handleOpenModal}
          >
            <PersonAddIcon />
          </IconButton>
        </Tooltip>
      </CustomToolbar>

      <Table
        tableName="Users"
        searchPlaceholder="Search by email"
        searchProperty="email"
        loading={isLoading}
        error={isError}
        count={count}
        fetchData={fetchUsers}
        searchData={search}
        onRowClick={onRowClick}
        columns={columns}
        data={users}
      />

      <Modal
        title="CREATE NEW USER"
        open={openModal}
        onClose={handleCloseModal}
      >
        <UsersCreate onClose={handleCloseModal} />
      </Modal>
    </>
  )
}

export default UsersList
