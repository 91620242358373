import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import UsersIcon from '@material-ui/icons/People'

import Error from '../../../components/Error'
import Spinner from '../../../components/Spinner'
import { getUsers } from '../utils'
import { useStyles } from '../styles'
import UserGrid from './UserGrid'

interface Props {
  groupId: string
  pageSize: number
}

interface State {
  loading: boolean
  error: string
  users: User[]
  userCount: number
}

const GroupUsers: React.FunctionComponent<Props> = ({ groupId, pageSize }) => {
  const [state, setState] = useState<State>({
    loading: true,
    error: '',
    users: [],
    userCount: 0
  })
  const { loading, error, users, userCount } = state
  const [page, setPage] = useState<number>(1)
  const [more, setMore] = useState<boolean>(false)
  const classes = useStyles()

  useEffect(() => {
    const load = async () => {
      setState(prev => ({
        ...prev,
        loading: true
      }))

      try {
        const { results, meta } = await getUsers(groupId, page)
        setState(prev => ({
          ...prev,
          loading: false,
          users: results,
          userCount: meta.count
        }))
      } catch (err) {
        setState(prev => ({
          ...prev,
          loading: false,
          error: err.message
        }))
      }
    }
    load()
  }, [groupId])

  const loadMore = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setMore(true)

    try {
      const { results, meta } = await getUsers(groupId, page + 1)
      setPage(page + 1)
      const newUsers = users.concat(results)
      setState(prev => ({
        ...prev,
        users: newUsers,
        userCount: meta.count
      }))
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: err.message
      }))
    }
    setMore(false)
  }

  if (!loading && error) {
    return <Error legend={error} />
  }

  return (
    <>
      <Grid item xs={12} className={classes.section}>
        <UsersIcon className={classes.icon} />
        <Typography component="span">
          <Box
            component="p"
            m={0}
            fontSize="h6.fontSize"
            fontWeight="fontWeightLight"
          >
            USERS {userCount > 0 && <strong>{userCount}</strong>}
          </Box>
        </Typography>
      </Grid>

      {loading ? (
        <Spinner legend="Loading Users..." />
      ) : (
        <Grid item xs={12}>
          {users.length ? (
            <Grid item xs={12}>
              <UserGrid users={users} />
            </Grid>
          ) : (
            <Typography>No users found</Typography>
          )}
          {!more && page + pageSize < userCount && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.moreButton}
              onClick={loadMore}
            >
              Show more
            </Button>
          )}
          {more && (
            <LinearProgress color="primary" className={classes.moreButton} />
          )}
        </Grid>
      )}
    </>
  )
}

export default GroupUsers
