import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useSnackbar } from 'material-ui-snackbar-provider'

import CreateForm from './CreateForm'
import { createDevice, bulkCreate } from './utils'
import { useStyles } from './styles'

interface Props {
  onClose: () => void
  onOpenCredentials: (data: DeviceData[]) => void
}

interface State {
  loading: boolean
  error: string
}

const CreateDevice: React.FunctionComponent<Props> = props => {
  const [activeTab, setActiveTab] = useState<number>(0)
  const [state, setState] = useState<State>({
    loading: false,
    error: ''
  })
  const { loading, error } = state
  const history = useHistory()
  const classes = useStyles()
  const snackbar = useSnackbar()

  const handleActiveTab = (event: React.ChangeEvent<{}>, value: any) => {
    setActiveTab(value)
  }

  const create = async (
    serialNumber: string,
    IMEI: string,
    ICCID: string,
    groupId: string
  ) => {
    setState(prev => ({
      ...prev,
      loading: true
    }))
    if (activeTab === 0) {
      try {
        const device = await createDevice({
          serialNumber,
          IMEI,
          ICCID,
          groupId
        })
        props.onClose()
        snackbar.showMessage(
          `Device with serial number ${serialNumber} created`
        )
        history.push(`/devices/${device.id}`)
      } catch (err) {
        setState(prev => ({
          ...prev,
          loading: false,
          error: err.message
        }))
      }
    } else {
      try {
        const devices = await bulkCreate(serialNumber, groupId)
        props.onClose()
        props.onOpenCredentials(devices)
      } catch (err) {
        setState(prev => ({
          ...prev,
          loading: false,
          error: err.message
        }))
      }
    }
  }

  return (
    <Grid container direction="column" className={classes.container}>
      <Tabs
        value={activeTab}
        onChange={handleActiveTab}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Single" />
        <Tab label="Batch" />
      </Tabs>
      <br />
      <div>
        <CreateForm
          type={activeTab === 0 ? 'single' : 'batch'}
          onClose={props.onClose}
          onSubmit={create}
          loading={loading}
          creationError={error}
        />
      </div>
    </Grid>
  )
}

export default CreateDevice
