import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Moment from 'react-moment'

import { useStyles } from './styles'

interface Props {
  log: Log
}

const LogItem: React.FunctionComponent<Props> = props => {
  const classes = useStyles()

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        <>
          <div className={classes.column}>
            <Typography className={classes.heading} noWrap>
              <Moment format="YYYY-MM-DD HH:mm">{props.log.createdAt}</Moment>
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.heading} noWrap>
              {props.log.connected ? 'Device connected' : 'Device disconnected'}
            </Typography>
          </div>
        </>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <div className={classes.row}>
          <Typography variant="caption" className={classes.secondaryHeading}>
            Server timestamp
          </Typography>
          <Typography variant="body1">
            <Moment format="MM/DD/YYYY HH:mm:ss">{props.log.createdAt}</Moment>
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="caption" className={classes.secondaryHeading}>
            Message
          </Typography>
          <Typography variant="body1">
            {props.log.connected ? 'Device connected' : 'Device disconnected'}
          </Typography>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default LogItem
