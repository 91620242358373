import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './styles'

interface Props {
  onYes: () => Promise<void>
  onNo: () => void
  submiting: boolean
  submitingText: string
}

const Confirm: React.FunctionComponent<Props> = props => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography variant="body2">
        {props.submiting
          ? `${props.submitingText}`
          : 'Are you sure to perform this action?'}
      </Typography>
      <div className={classes.buttonPanel}>
        <Button variant="outlined" onClick={props.onNo}>
          No
        </Button>
        <Button variant="contained" color="primary" onClick={props.onYes}>
          {props.submiting ? '...' : 'Yes'}
        </Button>
      </div>
    </div>
  )
}

export default Confirm
