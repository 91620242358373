import { apiGet } from '../../actions'

export const getDevices = async () => {
  const { devices } = await apiGet<DevicesResponse>(`devices`)

  return devices
}

export const getUsers = async () => {
  const { users } = await apiGet<UsersResponse>(`users`)

  return users
}

export const getGroups = async () => {
  const { groups } = await apiGet<GroupsResponse>(`groups`)

  return groups
}
