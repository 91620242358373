import { apiPost } from '../../../actions'

interface CreateBody {
  serialNumber: string
  IMEI: string
  ICCID: string
  groupId: string
}

export const createDevice = async ({
  serialNumber,
  IMEI,
  ICCID,
  groupId
}: CreateBody) => {
  const { device } = await apiPost<DeviceResponse>('devices', {
    serialNumber,
    properties: {
      IMEI,
      ICCID
    },
    groupId
  })

  return device
}

const parseDevices = (rawData: string) =>
  rawData.split(/\r?\n/).map(parsedLine => {
    const splittedProps = parsedLine.split(',')
    return {
      serialNumber: splittedProps[0],
      ...(splittedProps[1] !== '' && { IMEI: splittedProps[1] }),
      ...(splittedProps[2] !== '' && { ICCID: splittedProps[2] })
    }
  })

export const bulkCreate = async (csvData: string, groupId: string) => {
  const parsedDevices = parseDevices(csvData)

  const { devices } = await apiPost<BulkDevicesResponse>(
    'devices/bulk-create',
    {
      devices: parsedDevices,
      groupId
    }
  )

  return devices
}
