import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './styles'
import Alert from '@material-ui/lab/Alert'
import { localApiPost } from '../../actions'

const ForgotPassword: React.FunctionComponent = () => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState('')

  const classes = useStyles()

  const handleResetPassword = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    await localApiPost('profile/reset-password-token', { email })
    setSuccess('We sent you an email with password reset instructions.')
    setTimeout(() => {
      setSuccess('')
    }, 5000)
  }

  return (
    <>
      {success && (
        <Alert severity="success" className={classes.alert}>
          <span>{success}</span>
        </Alert>
      )}

      <Typography component="h1" variant="h5" className={classes.title}>
        Reset password
      </Typography>

      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={e => {
            setEmail(e.target.value)
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleResetPassword}
          disabled={!email}
        >
          Send reset password email
        </Button>
      </form>
    </>
  )
}

export default ForgotPassword
