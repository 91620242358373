import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(12)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20
  },
  details: {
    alignItems: 'start',
    flexDirection: 'row',
    fontSize: theme.typography.pxToRem(12)
  },
  detailItemFirst: {
    marginRight: '32px'
  },
  column: {
    flex: 'auto',
    '&:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  center: {
    marginBottom: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30vh'
  },
  toolbar: {
    width: '100%',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    padding: theme.spacing(3)
  },
  toolbarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  typeSelect: {
    flexBasis: '20%',
    minWidth: theme.spacing(20)
  },
  textCenter: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  logsContainer: {
    marginBottom: theme.spacing(3)
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(3)
  }
}))
