import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { Helmet } from 'react-helmet'

interface Props extends RouteProps {
  title?: string
}

const RouteWithTitle = ({ title, ...props }: Props) => {
  return (
    <>
      <Helmet>
        <title>{`Aritronix ${title ? `- ${title}` : ''}`}</title>
      </Helmet>
      <Route {...props} />
    </>
  )
}

export default RouteWithTitle
