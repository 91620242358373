import { LocalStorage } from '../constants'
import { decode } from 'jsonwebtoken'

export const getUserProfile = (): User => {
  const profile = localStorage.getItem(LocalStorage.USER)
  return JSON.parse(profile as string)
}

export const isAdmin = (jwt?: string): boolean => {
  const user: User = jwt ? (decode(jwt) as User) : getUserProfile()

  return user.role === 'admin'
}

export const downloadFile = (content: string, filename: string) => {
  const blob = new Blob([content], {
    type: 'text/plain'
  })
  const dataURI = `data:text/plain;charset=utf-8,${content}`

  const URL = window.URL || window.webkitURL
  const downloadURI =
    typeof URL.createObjectURL === 'undefined'
      ? dataURI
      : URL.createObjectURL(blob)

  let link = document.createElement('a')
  link.setAttribute('href', downloadURI)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getUserRoles = (): UserRole[] => {
  return [
    'admin',
    'dealer_admin',
    'dealer_group_admin',
    'rever_user',
    'read_only',
    'fleet_admin'
  ]
}
