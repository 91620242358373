import { apiGet, apiPost, apiPut, apiDelete } from '../../actions'

export const getDevice = async (id: string) => {
  const { device } = await apiGet<DeviceResponse>(`devices/${id}`)

  return device
}

export const generateCredentials = async (imei: string) => {
  const { credentials } = await apiPost<DeviceCredentialsResponse>(
    'devices/credentials',
    {
      imei
    }
  )

  return credentials
}

interface UpdateBody {
  serialNumber: string
  groupId: string
}

export const updateDevice = async (
  id: string,
  { serialNumber, groupId }: UpdateBody
) => {
  const { device } = await apiPut<DeviceResponse>(`devices/${id}`, {
    serialNumber,
    groupId
  })

  return device
}

export const deleteDevice = async (id: string) => {
  return apiDelete(`devices/${id}`)
}

export const banDevice = async (id: string) => {
  return apiPost(`devices/${id}/ban`, {})
}

export const unbanDevice = async (id: string) => {
  return apiPost(`devices/${id}/unban`, {})
}

export const resetDevice = async (id: string) => {
  const { device } = await apiPost<DeviceResponse>(`devices/${id}/reset`, {})
  return device
}
