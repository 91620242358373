import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormHelperText from '@material-ui/core/FormHelperText'
import { apiGet } from '../../../actions'
import Error from '../../../components/Error'
import { useStyles } from '../styles'

type CreationType = 'single' | 'batch'

interface Props {
  type?: CreationType
  loading: boolean
  creationError: string
  serial?: string
  group?: string | null
  onClose: () => void
  onSubmit: (serial: string, group: string) => Promise<void>
}

interface State {
  groups: Group[]
  error: string
  serial: string
  group: string | null
  groupName: string | null
}

const EditDevice: React.FunctionComponent<Props> = props => {
  const [state, setState] = useState<State>({
    groups: [],
    error: '',
    serial: props.serial || '',
    group: props.group || null,
    groupName: null
  })
  const [loadingGroups, setLoadingGroups] = useState<boolean>(false)

  const { groups, error, serial, group, groupName } = state
  const classes = useStyles()

  const getGroups = async () => {
    const { groups } = await apiGet<GroupsResponse>(
      `groups?pageSize=10000&page=1`
    )
    return groups.results
  }

  useEffect(() => {
    ;(async () => {
      setLoadingGroups(true)
      try {
        const groups = await getGroups()
        setState(prev => ({
          ...prev,
          groups
        }))
        setState(prev => ({
          ...prev,
          groupName: groups.find(g => g.id === group)?.name || null
        }))
      } catch (err) {
        setState(prev => ({
          ...prev,
          error: err.message
        }))
      }
      setLoadingGroups(false)
    })()
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setState(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleGroupChange = (e: React.ChangeEvent<{}>, value: Group | null) => {
    setState(prev => ({
      ...prev,
      group: value?.id || null
    }))
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (group) {
      props.onSubmit(serial, group)
    }
  }

  if (error || props.creationError) {
    return <Error legend={error || props.creationError} />
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl component="fieldset" fullWidth>
        <TextField
          id="serial"
          name="serial"
          label="Device's serial number"
          variant="outlined"
          value={serial}
          className={classes.input}
          onChange={handleInputChange}
        />
      </FormControl>
      <Autocomplete
        id="group"
        className={classes.autocomplete}
        options={groups}
        disabled={loadingGroups}
        disableClearable={true}
        loading={loadingGroups}
        getOptionLabel={(option: Group) => option.name}
        onChange={handleGroupChange}
        renderInput={params => (
          <TextField
            {...params}
            label="Select new group"
            name="group"
            variant="outlined"
            fullWidth
          />
        )}
      />
      <FormHelperText id="my-helper-text">
        Current group: {groupName || 'loading...'}
      </FormHelperText>
      <div className={classes.buttonPanel}>
        <Button onClick={props.onClose} style={{ marginRight: '2em' }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={props.loading || !group || !serial}
        >
          {`Updat${props.loading ? 'ing' : 'e'}`}
        </Button>
      </div>
    </form>
  )
}

export default EditDevice
