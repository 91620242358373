import { createMuiTheme } from '@material-ui/core'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#E9353F'
    },
    secondary: {
      main: '#5cae03'
    }
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 0
      }
    },
    MuiMenu: {
      paper: {
        width: 'auto'
      }
    },
    MuiSelect: {
      root: {
        '&::first-letter': {
          textTransform: 'uppercase'
        }
      }
    },
    MuiMenuItem: {
      root: {
        textTransform: 'capitalize'
      }
    },
    // @ts-ignore
    MuiPickerDTToolbar: {
      toolbar: {
        paddingLeft: '12px',
        paddingRight: '11px'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '2px'
      }
    },
    MuiAlert: {
      root: {
        alignItems: 'center'
      }
    }
  }
})
