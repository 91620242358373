import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import logo from '../../../media/images/logo-red.svg'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Tooltip from '@material-ui/core/Tooltip'
import UserMenu from './UserMenu'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AppBarUserMenu: {
      display: 'flex',
      flexGrow: 1,
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1,
      color: theme.palette.primary.main
    },
    iconLabel: {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightRegular
    },
    iconButton: {
      color: theme.palette.primary.main
    }
  })
)

const AppBarUserMenu: React.FunctionComponent = () => {
  const classes = useStyles()
  const { authTokens, setAuthTokens } = useAuth()
  const history = useHistory()

  const handleLogout = () => {
    localStorage.removeItem('jwt')
    setAuthTokens()
  }

  return (
    <div className={classes.AppBarUserMenu}>
      <Typography variant="h6" className={classes.title}>
        <Link to="/" style={{ color: 'inherit' }}>
          <img src={logo} alt="Aritronix dashboard" />
        </Link>
      </Typography>
      <Typography variant="button" className={classes.iconLabel}>
        {authTokens.name}
      </Typography>
      <UserMenu />
      <Tooltip
        title="Account admins"
        aria-label="Account admins"
        placement="bottom"
      >
        <IconButton
          aria-label="Account admins"
          aria-haspopup="true"
          className={classes.iconButton}
          onClick={() => history.push('/account-admins')}
        >
          <SupervisorAccountIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Log out" aria-label="Log out" placement="bottom">
        <IconButton
          aria-label="Log out"
          aria-haspopup="true"
          className={classes.iconButton}
          onClick={handleLogout}
        >
          <ExitToAppIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default AppBarUserMenu
