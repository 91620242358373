import { apiGet } from '../../actions'

interface QueryParams {
  page: number
  pageSize: number
  sortByString: string
  sortOrderString: string
  filterString: string
}

export const getUsers = async ({
  page,
  pageSize,
  sortByString,
  sortOrderString,
  filterString
}: QueryParams) => {
  const { users } = await apiGet<UsersResponse>(
    `users?role=admin&pageSize=${pageSize}&page=${page +
      1}${sortByString}${sortOrderString}${filterString}`
  )

  return users
}

export const searchUsers = async (
  email: string,
  page: number,
  pageSize: number
) => {
  const emailSearch = email !== undefined ? `email=${email}&` : ''

  const { users } = await apiGet<UsersResponse>(
    `users?${emailSearch}pageSize=${pageSize}&page=${page + 1}&role=admin`
  )

  return users
}
