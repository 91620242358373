// tslint:disable-next-line:no-reference
/// <reference path="../../index.d.ts"/>

import React, { useState } from 'react'
import './styles.scss'
import Layout from './Layout'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { theme } from '../constants/theme'
import { AuthContext, AuthTokens } from '../context/auth'
import PrivateRoute from './PrivateRoute'
import { Route, Switch } from 'react-router-dom'
import Login from '../pages/Login'
import { LocalStorage } from '../constants'
import ResetPassword from '../pages/ResetPassword'
import RouteWithTitle from './RouteWithTitle'

const App = () => {
  const storageUser = localStorage.getItem(LocalStorage.USER)
  const prevAuth = storageUser ? JSON.parse(storageUser) : null
  const [authTokens, setAuthTokens] = useState(prevAuth)

  const setTokens = (data: AuthTokens) => {
    if (data) {
      localStorage.setItem(LocalStorage.USER, JSON.stringify(data))
      setAuthTokens(data)
    } else {
      localStorage.removeItem(LocalStorage.USER)
      setAuthTokens(null)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <main className="App">
          <AuthContext.Provider
            value={{ authTokens, setAuthTokens: setTokens }}
          >
            <Switch>
              <RouteWithTitle
                title="Log in"
                exact
                path="/login"
                component={Login}
              />
              <RouteWithTitle
                title="Password reset"
                exact
                path="/reset-password"
                component={ResetPassword}
              />
              <PrivateRoute path="/" component={Layout} />
            </Switch>
          </AuthContext.Provider>
        </main>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
