import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  back: {
    color: theme.palette.text.primary,
    cursor: 'pointer'
  },
  container: {
    padding: theme.spacing(3)
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0, 2, 0),
    '&:first-of-type': {
      marginTop: 0
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  moreButton: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  details: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.spacing(1),
    padding: theme.spacing(2)
  },
  userTitle: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.spacing(1),
    padding: theme.spacing(2)
  },
  userGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.spacing(1),
    padding: theme.spacing(2)
  },
  devicesTitle: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: theme.spacing(1),
    padding: theme.spacing(2)
  },
  devicesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: theme.spacing(1),
    padding: theme.spacing(2)
  }
}))
