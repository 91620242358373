import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import BackIcon from '@material-ui/icons/ArrowBack'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { useSnackbar } from 'material-ui-snackbar-provider'

import Error from '../../components/Error'
import ErrorModal from '../../components/MessageModal'
import Spinner from '../../components/Spinner'
import Modal from '../../components/Modal'
import Confirm from '../../components/Confirm'
import CreateForm from '../Groups/CreateGroup/CreateForm'
import CustomToolbar from '../../components/CustomToolbar'
import ThreeDotMenu from '../../components/ThreeDotMenu'
import Details from './Details'
import GroupChildren from './GroupChildren'
import GroupUsers from './GroupUsers'
import GroupDevices from './GroupDevices'
import { getGroup, deleteGroup, updateGroup } from './utils'
import { useStyles } from './styles'
import Container from '@material-ui/core/Container'

interface RouteParams {
  id: string
}

interface State {
  loading: boolean
  error: ''
  group: Group | null
}

const Group: React.FunctionComponent = () => {
  const { id } = useParams<RouteParams>()
  const [state, setState] = useState<State>({
    loading: true,
    error: '',
    group: null
  })
  const { loading, error, group } = state
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [openError, setOpenError] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const history = useHistory()

  useEffect(() => {
    const load = async () => {
      setState(prev => ({
        ...prev,
        loading: true
      }))

      try {
        const group = await getGroup(id)
        setState(prev => ({
          ...prev,
          loading: false,
          group
        }))
      } catch (err) {
        setState(prev => ({
          ...prev,
          loading: false,
          error: err.message
        }))
      }
    }
    load()
  }, [id])

  const handleBack = () => {
    if (group?.parentId) {
      history.push(`/groups/${group.parentId}`)
    } else {
      history.push('/groups')
    }
  }

  const handleDelete = async () => {
    setDeleting(true)
    try {
      await deleteGroup(id)
      snackbar.showMessage(`Group ${id} deleted`)
      history.push('/groups')
    } catch (err) {
      setDeleting(false)
      handleOpenError()
      setState(prev => ({
        ...prev,
        error: err.message
      }))
    }
    handleCloseConfirm()
  }

  const handleOpenConfirm = () => setOpenConfirm(true)

  const handleCloseConfirm = () => setOpenConfirm(false)

  const handleOpenEdit = () => setOpenEdit(true)

  const handleCloseEdit = () => setOpenEdit(false)

  const handleOpenError = () => setOpenError(true)

  const handleCloseError = () => {
    setState(prev => ({
      ...prev,
      error: ''
    }))
    setOpenError(false)
  }

  const handleEdit = async (name: string, parentId: string | null) => {
    setUpdating(true)
    try {
      const updatedGroup = await updateGroup(id, name, parentId)
      setState(prev => ({
        ...prev,
        group: updatedGroup
      }))
      handleCloseEdit()
      snackbar.showMessage(`Group ${id} updated`)
    } catch (err) {
      handleOpenError()
      setState(prev => ({
        ...prev,
        error: err.message
      }))
    }
    setUpdating(false)
  }

  const menu = [
    {
      label: 'Edit group',
      action: handleOpenEdit
    },
    {
      label: 'Delete group',
      action: handleOpenConfirm
    }
  ]

  if (!loading && error && !openError) {
    return <Error legend={error} />
  }

  return (
    <>
      <CustomToolbar
        header={!loading && group ? `${group.name}` : ''}
        icon={<BackIcon className={classes.back} onClick={handleBack} />}
      >
        <ThreeDotMenu menu={menu} />
      </CustomToolbar>
      <Container>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.container}
        >
          <GroupChildren groupId={id} pageSize={9} />

          <Grid item xs={12} className={classes.section}>
            <InfoIcon className={classes.icon} />
            <Typography component="span">
              <Box
                component="p"
                m={0}
                fontSize="h6.fontSize"
                fontWeight="fontWeightLight"
              >
                DETAILS
              </Box>
            </Typography>
          </Grid>

          {loading || !group ? (
            <Spinner legend="Loading Details..." />
          ) : (
            <Grid item xs={12}>
              <Details group={group} />
            </Grid>
          )}

          <GroupUsers groupId={id} pageSize={3} />

          <GroupDevices groupId={id} pageSize={3} />
        </Grid>
      </Container>
      <Modal
        title="DELETE GROUP"
        open={openConfirm}
        onClose={handleCloseConfirm}
      >
        <Confirm
          onYes={handleDelete}
          onNo={handleCloseConfirm}
          submiting={deleting}
          submitingText="Deleting group"
        />
      </Modal>

      <Modal title="EDIT GROUP" open={openEdit} onClose={handleCloseEdit}>
        <CreateForm
          loading={updating}
          name={group?.name}
          role={group?.role}
          parentId={group?.parentId}
          onClose={handleCloseEdit}
          onSubmit={handleEdit}
          creationError={error}
        />
      </Modal>

      <ErrorModal
        title="ERROR"
        open={openError}
        onClose={handleCloseError}
        legend={error}
      />
    </>
  )
}

export default Group
