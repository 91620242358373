import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Pagination from '@material-ui/lab/Pagination'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import Error from '../../../components/Error'
import Spinner from '../../../components/Spinner'
import ReportsToolbar from './ReportsToolbar'
import ReportItem from './ReportItem'
import { getReports, ReportsListResponse } from './utils'
import { useStyles } from './styles'

interface State {
  loading: boolean
  error: string
  reports: ReportsListResponse['data']['results']
  pageCount: number
  page: number
  dateFrom: string
  dateTo: string
}

interface RouteParams {
  id: string
}

const DeviceReports: React.FunctionComponent = () => {
  const { id } = useParams<RouteParams>()

  const [state, setState] = useState<State>({
    loading: false,
    error: '',
    reports: [],
    pageCount: 1,
    page: 1,
    dateFrom: moment()
      .startOf('day')
      .subtract(3, 'day')
      .toISOString(),
    dateTo: moment().toISOString()
  })
  const { reports, loading, error, dateFrom, dateTo, page, pageCount } = state
  const classes = useStyles()

  const fetchReports = async (
    id: string,
    from: string,
    to: string,
    page: number
  ) => {
    setState(prev => ({ ...prev, loading: true }))

    try {
      const response = await getReports({ id, from, to, page })
      const pageSize = response.meta.pageSize as number

      setState(prev => ({
        ...prev,
        loading: false,
        reports: response.results,
        pageCount: Math.ceil((response.meta.count as number) / pageSize)
      }))
    } catch (err) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: (err as Error).message
      }))
    }
  }

  useEffect(() => {
    fetchReports(id, dateFrom, dateTo, page)
  }, [dateFrom, dateTo, page])

  const changeDate = (dateFrom: string, dateTo: string) => {
    setState(prev => ({
      ...prev,
      dateFrom,
      dateTo,
      page: 1
    }))
  }

  if (loading && reports.length === 0) {
    return <Spinner legend="Loading reports..." />
  }

  if (!loading && error) {
    return <Error legend={error} />
  }

  return (
    <Container>
      <Paper square>
        <ReportsToolbar onChangeDate={changeDate} from={dateFrom} to={dateTo} />
      </Paper>
      {!reports.length && (
        <Typography variant="subtitle1" className={classes.textCenter}>
          No reports found
        </Typography>
      )}
      <div className={classes.pagination}>
        <Pagination
          count={pageCount}
          page={page}
          color="primary"
          onChange={(e: unknown, page: number) => {
            setState(prev => ({
              ...prev,
              page
            }))
          }}
        />
      </div>
      {loading && <Spinner inline legend="Loading reports..." />}
      {!loading && (
        <div className={classes.logsContainer}>
          {reports.map((report, index) => (
            <ReportItem data={report} key={`log_${index}`} />
          ))}
        </div>
      )}
      {!loading && (
        <div className={classes.pagination}>
          <Pagination
            count={pageCount}
            page={page}
            color="primary"
            onChange={(e: unknown, page: number) => {
              setState(prev => ({
                ...prev,
                page
              }))
            }}
          />
        </div>
      )}
    </Container>
  )
}

export default DeviceReports
