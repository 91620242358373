import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import moment from 'moment'

import Table from '../../components/Table'
import CustomToolbar from '../../components/CustomToolbar'
import { getUsers, searchUsers } from './utils'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import UsersCreate from '../Users/UsersCreate'
import Modal from '../../components/Modal'

interface State {
  loading: boolean
  data: (string | number)[][]
  count: number
  error: string | null
}

const columns: Column[] = [
  {
    name: 'EMAIL',
    dbColumn: 'email',
    filter: false,
    filterType: 'textField'
  },
  {
    name: 'NAME',
    dbColumn: 'name',
    filter: false,
    filterType: 'textField'
  },
  {
    name: 'CREATED',
    dbColumn: 'created_at',
    filter: false
  },
  {
    name: 'ID',
    dbColumn: 'id',
    filter: false
  }
]

const AccountAdmins: React.FunctionComponent = () => {
  const [state, setState] = useState<State>({
    loading: false,
    data: [],
    count: 0,
    error: null
  })
  const { data, loading, error, count } = state
  const history = useHistory()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const fetchUsers = async ({
    page,
    pageSize,
    sortBy,
    sortOrder,
    filters
  }: FetchOptions) => {
    setState(prev => ({ ...prev, loading: true }))

    const sortByString = sortBy ? `&sortBy=${sortBy}` : ''
    const sortOrderString = sortOrder ? `&sortOrder=${sortOrder}` : ''
    const filterString = filters || ''

    const { results, meta } = await getUsers({
      page,
      pageSize,
      sortByString,
      sortOrderString,
      filterString
    })

    try {
      const data = results.map(user => [
        user.email,
        user.name,
        moment(user.createdAt).fromNow(),
        user.id
      ])

      setState(prev => ({
        ...prev,
        count: meta.count,
        loading: false,
        data
      }))
    } catch (error) {
      setState(prev => ({
        ...prev,
        loading: false,
        error
      }))
    }
  }

  const search = async ({ page, pageSize, body: { email } }: SearchOptions) => {
    setState(prev => ({ ...prev, loading: true }))

    const { results, meta } = await searchUsers(email as string, page, pageSize)

    try {
      const data = results.map(user => [
        user.email,
        user.name,
        moment(user.createdAt).fromNow(),
        user.id
      ])

      setState(prev => ({
        ...prev,
        count: meta.count,
        loading: false,
        data
      }))
    } catch (error) {
      setState(prev => ({
        ...prev,
        loading: false,
        error
      }))
    }
  }

  const onRowClick = (rowData: any) => {
    const _id = rowData[3]
    history.push(`/users/${_id}`)
  }

  return (
    <>
      <CustomToolbar
        header="Account admins"
        caption={`${count} users`}
        icon={<SupervisorAccountIcon />}
      >
        <Tooltip title={'Add new admin user.'}>
          <IconButton
            color="primary"
            aria-label="add new user"
            onClick={handleOpenModal}
          >
            <PersonAddIcon />
          </IconButton>
        </Tooltip>
      </CustomToolbar>

      <Table
        tableName="Account Admins"
        searchPlaceholder="Search by email"
        searchProperty="email"
        loading={loading}
        error={error}
        count={count}
        fetchData={fetchUsers}
        searchData={search}
        onRowClick={onRowClick}
        columns={columns}
        data={data}
      />

      <Modal
        title="CREATE ACCOUNT ADMIN"
        open={openModal}
        onClose={handleCloseModal}
      >
        <UsersCreate onClose={handleCloseModal} forAdmin={true} />
      </Modal>
    </>
  )
}

export default AccountAdmins
