export const filtersToString = (filters: string[], columns: any) => {
  const fieldsAndValues: Record<string, any> = []

  filters.forEach((filter, index) => {
    if (filter.length) {
      if (!columns[index].isProperty) {
        let value: string = filter[0]
        if (columns[index].name === 'STATUS') {
          value = filter[0] === 'ONLINE' ? 'true' : 'false'
        }

        fieldsAndValues.push({
          key: columns[index].dbColumn,
          value
        })
      }
    }
  })

  let filterString = ''
  fieldsAndValues.forEach((filter: Record<string, any>) => {
    filterString = filterString.concat(`&${filter.key}=${filter.value}`)
  })

  return filterString
}

export const filterProperties = (filterList: string[], columns: Column[]) => {
  let properties = {}
  filterList.filter((value: string, index: number) => {
    if (columns[index].isProperty && value[0] !== undefined) {
      properties = {
        ...properties,
        [columns[index].dbColumn]: value[0]
      }
    }
  })

  return properties
}
