export const localApiCall = async <T>(path: string, options = {}) => {
  try {
    let payload
    const res = await fetch(`api/${path}`, options)
    try {
      payload = await res.json()
    } catch {
      payload = null
    }

    if (!res.ok && payload) {
      const { message } = payload
      throw new Error(message)
    }

    return payload as T
  } catch (err) {
    throw err
  }
}

export function localApiPost<T>(path: string, body: any, options: any = {}) {
  return localApiCall<T>(path, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers || {})
    }
  })
}
