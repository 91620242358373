import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import { useStyles } from './styles'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { startCase, capitalize } from 'lodash'
import { formatUserRole } from '../../pages/Users/utils'

interface Props {
  field: Record<string, any>
  changeEvent: (e: React.ChangeEvent<any>) => void
  editMode: boolean
}

const FormSelect: React.FunctionComponent<Props> = props => {
  const classes = useStyles()
  const { defaultValue, readOnly, options } = props.field[1]
  const name = props.field[0]

  return (
    <FormControl className={classes.textField} variant="outlined">
      <InputLabel id={name}>{`${startCase(name)}`}</InputLabel>
      <Select
        labelId={name}
        name={name}
        defaultValue={defaultValue || ''}
        onChange={props.changeEvent}
        label={`${startCase(name)}`}
        disabled={readOnly ? readOnly : !props.editMode}
      >
        {options.length &&
          options.map((option: string) => (
            <MenuItem key={option} value={option}>
              {formatUserRole(option)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default FormSelect
