import { createContext, useContext } from 'react'

export interface AuthContextProps {
  authTokens: AuthTokens
  setAuthTokens: Function
}

export interface AuthTokens {
  id: string
  name: string
  email: string
  role: string
  groupId: string
  iat: number
  exp: number
}

export const AuthContext = createContext({} as AuthContextProps)

export const useAuth = () => useContext(AuthContext)
