import React, { useState } from 'react'
import { Alert } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useStyles } from './styles'
import { useLocation, useHistory } from 'react-router-dom'
import NotFound from '../NotFound'
import Grid from '@material-ui/core/Grid'
import logo from '../../../media/images/logo-red.svg'
import { Typography, Card, CardContent } from '@material-ui/core'
import queryString from 'query-string'
import { localApiPost } from '../../actions'

interface PasswordResetResponse {
  error?: boolean
  message?: string
}

const ResetPassword: React.FunctionComponent = () => {
  const location = useLocation()
  const history = useHistory()
  const { passwordResetToken, userId } = queryString.parse(location.search)

  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [error, setError] = useState<string | null>()
  const [success, setSuccess] = useState(false)

  const classes = useStyles()

  const handleResetPassword = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setError('')

    const res = await localApiPost<PasswordResetResponse>(
      'profile/reset-password',
      { userId, passwordResetToken, newPassword }
    )

    if (res && res.error) {
      setError(res.message)
    } else {
      setSuccess(true)
      setTimeout(() => {
        history.push('/login')
      }, 1000)
    }
  }

  const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value)
  }

  const handleConfirmNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(e.target.value)
  }

  if (!passwordResetToken || !userId) {
    return <NotFound />
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.grid}
    >
      <Grid item className={classes.header}>
        <img src={logo} alt="Aritronix dashboard" className={classes.logo} />
        <Typography variant="h4">Reset your password</Typography>
      </Grid>

      <Grid item>
        {error && (
          <Alert severity="error" className={classes.alert}>
            <span>{error}</span>
          </Alert>
        )}

        {success && (
          <Alert severity="success" className={classes.alert}>
            <span>Password changed successfully! Redirecting to login...</span>
          </Alert>
        )}
      </Grid>

      <Grid item>
        <Card>
          <CardContent>
            <form noValidate className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New password"
                type="password"
                id="newPassword"
                autoComplete="new-password"
                onChange={handleNewPassword}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="new_password_confirm"
                label="Confirm new password"
                type="password"
                id="new_password_again"
                autoComplete="new-password"
                onChange={handleConfirmNewPassword}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleResetPassword}
                disabled={
                  !newPassword ||
                  !confirmNewPassword ||
                  confirmNewPassword !== newPassword
                }
              >
                Reset password
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ResetPassword
