import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'

import Container from '@material-ui/core/Container'

import { MqttClient } from 'mqtt'

import { apiPost, mqttService } from '../../../actions'
import MessagesPanel from './MessagesPanel'
import PublishPanel from './PublishPanel'
import { useStyles } from './styles'
import Error from '../../../components/Error'
import Spinner from '../../../components/Spinner'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

interface CommandResponseBody {
  msisdn: string
  iccid: string
  simProvider: '1nce' | 'kore'
  sent: boolean
  response: {
    status?: number
    message?: string
  }
}

const Main: React.FC<{ device: DeviceData }> = ({ device }) => {
  const id = device.id
  const classes = useStyles()
  const [mqttClient, setMqttClient] = useState<MqttClient | null>(null)
  const [connected, setConnected] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [cmdSuccessStatus, setCmdSuccessStatus] = useState<string>('')
  const [cmdErrorStatus, setCmdErrorStatus] = useState<string>('')
  const [messages, setMessages] = useState<MqttMessage[]>([])

  useEffect(() => {
    const client = mqttService.getClient()
    setMqttClient(client)

    client.on('connect', () => {
      mqttService.subscribeToAll(client, id as string)
      mqttService.subscribeToQueclinkIncoming(
        client,
        String(device.properties.IMEI)
      )
      setConnected(true)
    })

    client.on('error', (err: Error) => setError(err.message))

    const handleMessage = (channel: string, message: string) => {
      const timestamp = new Date()
      setMessages(prev => [
        ...prev,
        {
          channel,
          message,
          timestamp
        }
      ])
    }

    mqttService.onMessage(client, handleMessage)

    return () => {
      setConnected(false)
      mqttService.closeConnection(client, id as string)
      setMqttClient(null)
    }
  }, [])

  const onPublish = (message: string) => {
    if (mqttClient) {
      setLoading(true)
      mqttService.publish(
        mqttClient,
        `queclink/outgoing/${device.properties.IMEI}`,
        message.trim(),
        err => {
          if (err) {
            setCmdErrorStatus(err.toString())
          } else {
            setCmdSuccessStatus(`Command successfully sent`)
          }
          setLoading(false)
        }
      )
    }
  }

  const onSendSMS = async (message: string) => {
    try {
      setLoading(true)
      const res = await apiPost<CommandResponseBody>(
        `devices/${device.id}/command`,
        {
          payload: message.trim()
        }
      )

      if (res.sent) {
        setCmdSuccessStatus(`Command successfully sent`)
      } else {
        const errorDetail = res.response.message
          ? `Command failed: ${res.response.message}`
          : `Command failed with status code: ${res.response.status}`
        setCmdErrorStatus(errorDetail)
      }
    } catch (err) {
      setCmdErrorStatus(`Command failed: ${err}`)
    } finally {
      setLoading(false)
    }
  }

  if (!connected && error) {
    return <Error legend={error} />
  }

  return (
    messages && (
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12}>
          <PublishPanel
            onPublish={onPublish}
            onSendSMS={onSendSMS}
            loading={loading}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={loading || !!cmdSuccessStatus || !!cmdErrorStatus}
          >
            {cmdSuccessStatus ? (
              <Alert
                severity="success"
                onClose={() => {
                  setCmdSuccessStatus('')
                }}
              >
                {cmdSuccessStatus}
              </Alert>
            ) : cmdErrorStatus ? (
              <Alert
                severity="error"
                onClose={() => {
                  setCmdErrorStatus('')
                }}
              >
                {cmdErrorStatus}
              </Alert>
            ) : (
              <Alert severity="info">Sending command...</Alert>
            )}
          </Snackbar>
        </Grid>

        <Grid item xs={12}>
          <MessagesPanel messages={messages} connected={connected} />
        </Grid>
      </Grid>
    )
  )
}

const DeviceMessages: React.FC<{
  device: DeviceData | null
  loading: boolean
}> = ({ device, loading }) => {
  const classes = useStyles()

  if (loading || !device) {
    return <Spinner legend="Loading device..." />
  }

  return (
    <Container>
      <Grid container spacing={3} className={classes.grid}>
        <Main device={device} />
      </Grid>
    </Container>
  )
}

export default DeviceMessages
