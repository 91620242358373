import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import HierarchyIcon from '@material-ui/icons/AccountTreeOutlined'
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined'
import moment from 'moment'

import { useStyles } from './styles'

interface Props {
  group: Group | null
  users: User[]
}
const AssociatedUsers: React.FunctionComponent<Props> = ({ group, users }) => {
  const classes = useStyles()

  return (
    <Grid item>
      <Paper square className={classes.paper}>
        <Typography variant="h6" className={classes.paperHeader}>
          <Box
            component="p"
            m={0}
            fontSize="h6.fontSize"
            fontWeight="fontWeightLight"
          >
            ASSOCIATED END-USERS
          </Box>
        </Typography>
        {group && (
          <Typography variant="caption">
            This device is a part of the group:
          </Typography>
        )}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          className={classes.group}
        >
          <Link
            to={group ? `/groups/${group.id}` : ''}
            className={classes.groupLink}
          >
            <HierarchyIcon color="primary" className={classes.groupIcon} />
            <Typography variant="h6">
              {group ? group.name : 'This device is not in any groups'}
            </Typography>
            {group && (
              <Typography variant="body1">
                Last modified: {moment(group.updatedAt).fromNow()}
              </Typography>
            )}
          </Link>
        </Box>

        <List>
          {users.map(user => (
            <Link
              to={`/users/${user.id}`}
              className={classes.link}
              key={user.id}
            >
              <ListItem
                alignItems="flex-start"
                key={user.id}
                className={classes.listItem}
              >
                <ListItemAvatar>
                  <AccountCircleIcon fontSize="large" color="primary" />
                </ListItemAvatar>
                <ListItemText
                  primary={user.email}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        {`Created: ${moment(user.createdAt).fromNow()}`}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </Link>
          ))}
        </List>
      </Paper>
    </Grid>
  )
}

export default AssociatedUsers
