import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import DevicesIcon from '@material-ui/icons/Devices'

import Error from '../../../components/Error'
import Spinner from '../../../components/Spinner'
import { useStyles } from '../styles'
import DeviceGrid from './DeviceGrid'
import { getDevices } from '../utils'

interface Props {
  groupId: string
  pageSize: number
}

interface State {
  loading: boolean
  error: string
  devices: DeviceData[]
  deviceCount: number
}

const GroupDevices: React.FunctionComponent<Props> = ({
  groupId,
  pageSize
}) => {
  const [state, setState] = useState<State>({
    loading: true,
    error: '',
    devices: [],
    deviceCount: 0
  })
  const { loading, error, devices, deviceCount } = state
  const [page, setPage] = useState<number>(1)
  const [more, setMore] = useState<boolean>(false)
  const classes = useStyles()

  useEffect(() => {
    const load = async () => {
      setState(prev => ({
        ...prev,
        loading: true
      }))

      try {
        const { results, meta } = await getDevices(groupId, page)
        setState(prev => ({
          ...prev,
          loading: false,
          devices: results,
          deviceCount: meta.count
        }))
      } catch (err) {
        setState(prev => ({
          ...prev,
          loading: false,
          error: err.message
        }))
      }
    }
    load()
  }, [groupId])

  const loadMore = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setMore(true)
    try {
      const { results, meta } = await getDevices(groupId, page + 1)
      setPage(page + 1)
      const newDevices = devices.concat(results)
      setState(prev => ({
        ...prev,
        devices: newDevices,
        deviceCount: meta.count
      }))
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: err.message
      }))
    }

    setMore(false)
  }

  if (!loading && error) {
    return <Error legend={error} />
  }

  return (
    <>
      <Grid item xs={12} className={classes.section}>
        <DevicesIcon className={classes.icon} />
        <Typography component="span">
          <Box
            component="p"
            m={0}
            fontSize="h6.fontSize"
            fontWeight="fontWeightLight"
          >
            DEVICES {deviceCount > 0 && <strong>{deviceCount}</strong>}
          </Box>
        </Typography>
      </Grid>

      {loading ? (
        <Spinner legend="Loading Devices..." />
      ) : (
        <Grid item xs={12}>
          {devices.length ? (
            <DeviceGrid devices={devices} />
          ) : (
            <Typography>No devices found</Typography>
          )}
          {!more && page + pageSize < deviceCount && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.moreButton}
              onClick={loadMore}
            >
              Show more
            </Button>
          )}
          {more && (
            <LinearProgress color="primary" className={classes.moreButton} />
          )}
        </Grid>
      )}
    </>
  )
}

export default GroupDevices
