import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { apiGet } from '../../../actions'
import Error from '../../../components/Error'
import { useStyles } from './styles'

type CreationType = 'single' | 'batch'

interface Props {
  type?: CreationType
  loading: boolean
  creationError: string
  serial?: string
  IMEI?: string | null
  ICCID?: string | null
  onClose: () => void
  onSubmit: (
    serial: string,
    IMEI: string,
    ICCID: string,
    group: string
  ) => Promise<void>
}

interface State {
  groups: Group[]
  error: string
  serial: string
  IMEI: string
  ICCID: string
  deviceType: string
  group: string | null
}

const CreateForm: React.FunctionComponent<Props> = props => {
  const [state, setState] = useState<State>({
    groups: [],
    error: '',
    serial: props.serial || '',
    IMEI: props.IMEI || '',
    ICCID: props.ICCID || '',
    deviceType: 'trailer',
    group: null
  })
  const { groups, error, serial, IMEI, ICCID, deviceType, group } = state
  const classes = useStyles()
  const isEdit = !!props.serial

  const getGroups = async (deviceType: string) => {
    const roles = deviceType === 'trailer' ? ['fleet'] : ['rever', 'device']
    const { groups } = await apiGet<GroupsResponse>(
      `groups?role=${roles.join(',')}&pageSize=10000&page=1`
    )
    return groups.results
  }

  useEffect(() => {
    const load = async () => {
      try {
        const groups = await getGroups(deviceType)
        setState(prev => ({
          ...prev,
          groups
        }))
      } catch (err) {
        setState(prev => ({
          ...prev,
          error: err.message
        }))
      }
    }
    load()
  }, [deviceType])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setState(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setState(prev => ({
      ...prev,
      deviceType: value
    }))
  }

  const handleGroupChange = (e: React.ChangeEvent<{}>, value: Group | null) => {
    setState(prev => ({
      ...prev,
      group: value?.id || null
    }))
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (group) {
      props.onSubmit(serial, IMEI, ICCID, group)
    }
  }

  if (error || props.creationError) {
    return <Error legend={error || props.creationError} />
  }

  return (
    <form onSubmit={handleSubmit}>
      {(props.type === 'single' || isEdit) && (
        <div>
          <TextField
            id="serial"
            name="serial"
            label="Device's serial number"
            variant="outlined"
            value={serial}
            className={classes.input}
            onChange={handleInputChange}
            required
          />
          <TextField
            id="IMEI"
            name="IMEI"
            label="Device's IMEI"
            variant="outlined"
            value={IMEI}
            className={classes.input}
            onChange={handleInputChange}
            required
          />
          <TextField
            id="ICCID"
            name="ICCID"
            label="Device's ICCID"
            variant="outlined"
            value={ICCID}
            className={classes.input}
            onChange={handleInputChange}
            required
          />
        </div>
      )}
      {props.type === 'batch' && (
        <TextField
          id="serial"
          name="serial"
          label="Input data"
          variant="outlined"
          multiline
          rowsMax={5}
          placeholder="<Serial>,<IMEI>,<ICCID>..."
          className={classes.fullWidth}
          onChange={handleInputChange}
          required
        />
      )}
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" className={classes.radioLabel}>
          Device Type
        </FormLabel>
        <RadioGroup
          className={classes.radio}
          aria-label="device-type"
          name="deviceType"
          value={deviceType}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="trailer"
            control={<Radio color="primary" disabled={isEdit} />}
            label="Trailer"
          />
          <FormControlLabel
            value="ride"
            control={<Radio color="primary" disabled={isEdit} />}
            label="Ride"
          />
        </RadioGroup>
      </FormControl>
      {!isEdit && (
        <Autocomplete
          id="group"
          options={groups}
          getOptionLabel={(option: Group) => option.name}
          onChange={handleGroupChange}
          renderInput={params => (
            <TextField
              {...params}
              name="group"
              variant="outlined"
              className={classes.fullWidth}
            />
          )}
        />
      )}
      <br />
      <div className={classes.buttonPanel}>
        <Button onClick={props.onClose} style={{ marginRight: '2em' }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={props.loading || !group}
        >
          {isEdit
            ? `Updat${props.loading ? 'ing' : 'e'}`
            : `Creat${props.loading ? 'ing' : 'e'}`}
        </Button>
      </div>
    </form>
  )
}

export default CreateForm
