import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import Error from '../../../components/Error'
import Spinner from '../../../components/Spinner'
import LogsToolbar from './LogsToolbar'
import LogItem from './LogItem'
import { getLogs } from './utils'
import { useStyles } from './styles'

interface State {
  loading: boolean
  error: string
  logs: Log[]
}

interface RouteParams {
  id: string
}

const DeviceLogs: React.FunctionComponent = () => {
  const { id } = useParams<RouteParams>()
  const [state, setState] = useState<State>({
    loading: false,
    error: '',
    logs: []
  })
  const { logs, loading, error } = state
  const classes = useStyles()

  const fetchLogs = async (id: string, from: string, to: string) => {
    setState(prev => ({ ...prev, loading: true }))

    try {
      const { results } = await getLogs({ id, from, to })

      setState(prev => ({
        ...prev,
        loading: false,
        logs: results
      }))
    } catch (err) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: err.message
      }))
    }
  }

  useEffect(() => {
    const from = moment()
      .subtract(29, 'days')
      .toISOString()
    const to = moment().toISOString()
    fetchLogs(id, from, to)
  }, [])

  const changeDate = (from: string, to: string) => {
    fetchLogs(id, from, to)
  }

  if (loading) {
    return <Spinner legend="Loading logs..." />
  }

  if (!loading && error) {
    return <Error legend={error} />
  }

  return (
    <Container>
      <Paper square>
        <LogsToolbar onChangeDate={changeDate} />
      </Paper>
      {!logs.length && (
        <Typography variant="subtitle1" className={classes.textCenter}>
          No logs published
        </Typography>
      )}
      <div className={classes.logsContainer}>
        {logs.map((log: Log, index: number) => (
          <LogItem log={log} key={`log_${index}`} />
        ))}
      </div>
    </Container>
  )
}

export default DeviceLogs
