import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(1)
  },
  radio: {
    display: 'flex',
    flexDirection: 'row'
  },
  input: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.5)
  },
  form: {
    overflow: 'hidden'
  },
  addParentLabel: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    cursor: 'pointer',
    color: theme.palette.primary.main,
    transition: 'all 0.2s',
    '&:hover': {
      opacity: 0.75
    }
  },
  fullWidth: {
    width: '100%'
  },
  buttonPanel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3)
  }
}))
