import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { decode } from 'jsonwebtoken'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Alert } from '@material-ui/lab'

import { apiPost } from '../../actions'
import { useAuth } from '../../context/auth'
import { useStyles } from './styles'
import { isAdmin } from '../../lib/util'

const SignIn: React.FunctionComponent = () => {
  const classes = useStyles()
  const { setAuthTokens } = useAuth()
  const [state, setState] = useState({
    loading: false,
    error: '',
    loggedIn: false,
    data: {
      emailAddress: '',
      password: ''
    }
  })

  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    setState(prev => ({ ...prev, loading: true, error: '' }))
    try {
      const { emailAddress, password } = state.data
      const { jwt } = await apiPost<AuthResponse>('users/auth', {
        emailAddress,
        password
      })

      if (isAdmin(jwt)) {
        localStorage.setItem('jwt', jwt)
        setAuthTokens(decode(jwt))
        setState(prev => ({
          ...prev,
          loggedIn: true
        }))
      } else {
        setState(prev => ({
          ...prev,
          loading: false,
          error: `Access Denied, You don’t have permission to access on this site.`
        }))
      }
    } catch (error) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: 'Invalid email or password. Please try again'
      }))
    }
  }

  if (state.loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <>
      {state.error && (
        <Alert severity="error" className={classes.alert}>
          {state.error}
        </Alert>
      )}

      <Typography component="h1" variant="h5" className={classes.title}>
        Sign in
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="emailAddress"
          label="Email Address"
          name="emailAddress"
          autoFocus
          value={state.data.emailAddress}
          onChange={e => {
            const emailAddress = e.target.value
            setState(prev => ({
              ...prev,
              data: { ...prev.data, emailAddress }
            }))
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          value={state.data.password}
          onChange={e => {
            const password = e.target.value
            setState(prev => ({
              ...prev,
              data: { ...prev.data, password }
            }))
          }}
        />
        <div className={classes.formActions}>
          {state.loading ? (
            <CircularProgress className={classes.spinner} size={30} />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={e => handleLogin(e)}
            >
              Sign In
            </Button>
          )}
        </div>
      </form>
    </>
  )
}

export default SignIn
