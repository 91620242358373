import React from 'react'
import { Link } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import GroupsIcon from '@material-ui/icons/AccountTreeOutlined'
import UsersIcon from '@material-ui/icons/People'
import DevicesIcon from '@material-ui/icons/Devices'

import { useStyles } from './styles'
import CardActionArea from '@material-ui/core/CardActionArea'

interface Props {
  group: Group
}

const GroupCard: React.FunctionComponent<Props> = ({ group }) => {
  const classes = useStyles()

  return (
    <Link to={`/groups/${group.id}`}>
      <Card variant="outlined" className={classes.groupCard}>
        <CardActionArea>
          <CardContent>
            <Typography variant="h6">{group.name}</Typography>
            <br />
            <div className={classes.infoPanel}>
              <div className={classes.infoItem}>
                <GroupsIcon className={classes.icon} />
                <Typography>{group.childCount}</Typography>
              </div>
              <div className={classes.infoItem}>
                <UsersIcon className={classes.icon} />
                <Typography>{group.userCount}</Typography>
              </div>
              <div className={classes.infoItem}>
                <DevicesIcon className={classes.icon} />
                <Typography>{group.deviceCount}</Typography>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  )
}

export default GroupCard
