import React from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

interface Props {
  legend?: string
  inline?: boolean
}

export const Spinner: React.FunctionComponent<Props> = props => {
  const { legend, inline = false } = props
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ height: inline ? 'auto' : '100%' }}
    >
      <CircularProgress />
      {legend && (
        <Typography
          variant="h5"
          style={{ marginTop: '1rem' }}
          color="textPrimary"
        >
          {legend}
        </Typography>
      )}
    </Grid>
  )
}

export default Spinner
