import React from 'react'
import FormSelect from './FormSelect'
import FormTextField from './FormTextField'
import FormSelectSearch from './FormSelectSearch'

export const renderField = (
  field: any,
  changeEvent: (e: React.ChangeEvent<HTMLInputElement>) => void,
  editMode: boolean
) => {
  const { type } = field[1]

  if (type === 'select') {
    return (
      <FormSelect
        key={field[0]}
        field={field}
        changeEvent={changeEvent}
        editMode={editMode}
      />
    )
  }

  if (type === 'select-search') {
    return (
      <FormSelectSearch
        key={field[0]}
        field={field}
        changeEvent={changeEvent}
        onSearch={field[1].onSearch}
        editMode={editMode}
      />
    )
  }

  return (
    <FormTextField
      key={field[0]}
      field={field}
      changeEvent={changeEvent}
      editMode={editMode}
    />
  )
}
