import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Map from '../../../components/Map'
import { useStyles } from './styles'
import { MenuItem, Select, Checkbox, FormControlLabel } from '@material-ui/core'
import { apiGet } from '../../../actions/api'
import moment from 'moment'

interface Props {
  device: DeviceData
}

interface LocationItem {
  timestamp: string
  altitude: number
  speed: number
  azimuth: number
  point: [number, number]
}

const heading: Record<string, string> = {
  0: 'N',
  45: 'NE',
  90: 'E',
  135: 'SE',
  180: 'S',
  225: 'SW',
  270: 'W',
  315: 'NW'
}

function isSpeedEnoughToConsiderMoving(speed: number): boolean {
  return speed > 3
}

function getMovingDirection(item: LocationItem) {
  const k = String(item.azimuth)
  return isSpeedEnoughToConsiderMoving(item.speed) && heading[k] !== null
    ? heading[k]
    : null
}

function getBreadcrumbIcon(item: LocationItem): google.maps.Icon {
  const k = String(item.azimuth)
  const url = isSpeedEnoughToConsiderMoving(item.speed)
    ? `https://dashboard.motosense.com/images/pins/${heading[
        k
      ].toLowerCase()}.png`
    : 'https://dashboard.motosense.com/images/pins/stopped.png'
  return {
    anchor: new google.maps.Point(10, 10),
    url
  }
}

const Location: React.FunctionComponent<Props> = ({ device }) => {
  const classes = useStyles()
  const [timeframeSelectorOpen, setTimeframeSelectorOpen] = useState(false)
  const [timeframe, setTimeframe] = useState('0')
  const [locations, setLocations] = useState<LocationItem[]>([])
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [polyline, setPolyline] = useState<google.maps.Polyline | null>(null)
  const [showBreadCrumbs, setShowBreadCrumbs] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState<google.maps.Marker[]>([])

  const { latitude: lat, longitude: lng } = device

  const handleTimeframeChange = async (value: string) => {
    setTimeframe(value)
  }

  useEffect(() => {
    ;(async () => {
      if (timeframe !== '0') {
        try {
          const from = moment()
            .subtract(...timeframe.split(','))
            .toISOString()
          const { data } = await apiGet<{
            data: { locations: { list: LocationItem[] } }
          }>(`devices/${device.id}/history?includeLocations=1&from=${from}`)

          setLocations(data.locations.list)
        } catch (err) {
          console.error(err)
        }
      } else {
        setLocations([])
      }
    })()
  }, [timeframe])

  useEffect(() => {
    if (!map) {
      return
    }

    if (polyline) {
      polyline.setMap(null)
      setPolyline(null)
    }

    if (breadCrumbs) {
      breadCrumbs.forEach(item => {
        item.setMap(null)
      })
    }

    const bounds = new google.maps.LatLngBounds()
    const path: google.maps.LatLngLiteral[] = []
    const markers: google.maps.Marker[] = []

    if (locations.length > 0) {
      locations.forEach(entry => {
        const point = { lat: entry.point[1], lng: entry.point[0] }
        bounds.extend(point)
        path.push(point)
        if (showBreadCrumbs) {
          const icon = getBreadcrumbIcon(entry)
          const dir = getMovingDirection(entry)
          const title = dir
            ? `Moving ${dir} at ${entry.speed.toFixed(2)} mph (${moment(
                entry.timestamp
              ).format('YYYY-MM-DD HH:mm:ss')})`
            : ''
          markers.push(
            new window.google.maps.Marker({ position: point, map, icon, title })
          )
        }
      })

      setBreadCrumbs(markers)

      const flightPath = new google.maps.Polyline({
        path,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        map
      })

      map.fitBounds(bounds)
      setPolyline(flightPath)
    } else {
      map.setCenter({ lat, lng })
    }
  }, [locations, showBreadCrumbs])

  return (
    <Grid container direction="column" style={{ height: '100%' }}>
      <Paper square className={classes.paper}>
        <div className={classes.paperHeader}>
          <Typography variant="h6">
            <Box
              component="p"
              m={0}
              fontSize="h6.fontSize"
              fontWeight="fontWeightLight"
            >
              LOCATION
            </Box>
          </Typography>
          <div>
            <FormControlLabel
              value="end"
              control={<Checkbox color="secondary" value="1" />}
              label="Breadcrumbs"
              labelPlacement="end"
              onChange={e => {
                setShowBreadCrumbs((e.target as HTMLInputElement).checked)
              }}
            />
            <Select
              open={timeframeSelectorOpen}
              onClose={() => setTimeframeSelectorOpen(false)}
              onOpen={() => setTimeframeSelectorOpen(true)}
              value={timeframe}
              onChange={event => {
                handleTimeframeChange(event.target.value as string)
              }}
              className={classes.timeframeSelector}
            >
              <MenuItem value="0">Current location</MenuItem>
              <MenuItem value="30,minutes">Last 30 minutes</MenuItem>
              <MenuItem value="1,hour">Last hour</MenuItem>
              <MenuItem value="6,hours">Last 6 hours</MenuItem>
              <MenuItem value="12,hours">Last 12 hours</MenuItem>
              <MenuItem value="24,hours">Last 24 hours</MenuItem>
              <MenuItem value="3,days">Last 3 days</MenuItem>
              <MenuItem value="7,days">Last 7 days</MenuItem>
            </Select>
          </div>
        </div>

        {lat && lng ? (
          <Map
            options={{
              center: { lat, lng },
              zoom: 12
            }}
            onMount={(map: google.maps.Map) => {
              setMap(map)
              new window.google.maps.Marker({ position: { lat, lng }, map })
            }}
          />
        ) : (
          <Typography variant="h5" className={classes.noLocationMessage}>
            No location information are available
          </Typography>
        )}
      </Paper>
    </Grid>
  )
}

export default Location
