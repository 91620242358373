import { apiGet } from '../../../actions'

export const getGroups = async (parentId?: string) => {
  const parent = parentId || 'top'

  const { groups } = await apiGet<GroupsResponse>(
    `groups?count=devices,end-users,children&pageSize=1000&page=1&parentId=${parent}`
  )

  return groups.results
}

export const searchGroup = async (name: string) => {
  const { groups } = await apiGet<GroupsResponse>(
    `groups?count=devices,end-users,children&pageSize=1000&page=1&name=${encodeURIComponent(
      name
    )}`
  )

  return groups.results
}
