import React, { useState } from 'react'
import { getUserRoles } from '../../../lib/util'
import FormEdition from '../../../components/Form/FormEdition'
import { omit } from 'lodash'

interface Props {
  user: User
  onSave: (updatedUser: Partial<User>, callback: () => void) => Promise<void>
  saving: boolean
}

const UsersOverview: React.FunctionComponent<Props> = props => {
  const [updatedUser, setUpdatedUser] = useState<Partial<User>>({})
  const userIsAdmin = props.user.role === 'admin'

  const fieldsProperties = {
    ...(!userIsAdmin && {
      groupId: {
        readOnly: true
      }
    }),
    createdAt: {
      readOnly: true
    },
    updatedAt: {
      readOnly: true
    },
    id: {
      readOnly: true
    },
    ...(!userIsAdmin && {
      role: {
        type: 'select',
        options: getUserRoles().filter(role => role !== 'admin')
      }
    }),
    email: {
      readOnly: true,
      htmlType: 'email'
    }
  }

  const handleUpdateUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setUpdatedUser(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleOnSave = (callback: () => void) => {
    return props.onSave(updatedUser, callback)
  }

  return (
    <FormEdition
      title="Overview"
      fields={
        userIsAdmin
          ? omit(props.user, ['properties', 'role', 'groupId'])
          : omit(props.user, 'properties')
      }
      fieldsProperties={fieldsProperties}
      onSave={handleOnSave}
      onFieldUpdate={handleUpdateUser}
      saving={props.saving}
      savingError={''}
    />
  )
}

export default UsersOverview
