import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { useSnackbar } from 'material-ui-snackbar-provider'

import { apiPost } from '../../../actions'
import CreateForm from './CreateForm'
import { useStyles } from './styles'

interface Props {
  onClose: () => void
}

interface State {
  loading: boolean
  error: string
}

const CreateGroup: React.FunctionComponent<Props> = props => {
  const [state, setState] = useState<State>({
    loading: false,
    error: ''
  })
  const { loading, error } = state
  const history = useHistory()
  const classes = useStyles()
  const snackbar = useSnackbar()

  const create = async (
    name: string,
    parentId?: string | null,
    role?: string
  ) => {
    setState(prev => ({
      ...prev,
      loading: true
    }))
    try {
      const { group } = await apiPost<GroupResponse>('groups', {
        name,
        role,
        ...(parentId && { parentId })
      })
      props.onClose()
      snackbar.showMessage(`${name} Group created`)
      history.push(`/groups/${group.id}`)
    } catch (err) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: err.message
      }))
    }
  }

  return (
    <Grid container direction="column" className={classes.container}>
      <CreateForm
        onClose={props.onClose}
        onSubmit={create}
        loading={loading}
        creationError={error}
      />
    </Grid>
  )
}

export default CreateGroup
