import React, { useState, useEffect } from 'react'
import { useParams, Link, Route, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import DevicesIcon from '@material-ui/icons/Devices'
import { useSnackbar } from 'material-ui-snackbar-provider'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import Error from '../../components/Error'
import Modal from '../../components/Modal'
import Confirm from '../../components/Confirm'
import CustomToolbar from '../../components/CustomToolbar'
import EditDevice from './EditDevice'
import ThreeDotMenu from '../../components/ThreeDotMenu'
import Credentials from './Credentials'
import Regenerate from './Credentials/Regenerate'
import DeviceOverview from './DeviceOverview'
import DeviceLogs from './DeviceLogs'
import DeviceReports from './DeviceReports'
import DeviceAlerts from './DeviceAlerts'
import DeviceMessages from './DeviceMessages'
import {
  getDevice,
  generateCredentials,
  updateDevice,
  deleteDevice,
  banDevice,
  unbanDevice,
  resetDevice
} from './utils'
import { useStyles } from './styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

interface RouteParams {
  id: string
}

interface State {
  loading: boolean
  error: string
  device: DeviceData | null
  credentials: DeviceCredentials | null
}

const Device: React.FC = () => {
  let { id } = useParams<RouteParams>()
  const [state, setState] = useState<State>({
    loading: false,
    error: '',
    device: null,
    credentials: null
  })
  const { loading, error, device, credentials } = state
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)
  const [openCredentials, setOpenCredentials] = useState<boolean>(false)
  const [openRegenerate, setOpenRegenerate] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const snackbar = useSnackbar()
  const history = useHistory()

  const tabs = ['overview', 'messages', 'logs', 'reports', 'alerts']

  useEffect(() => {
    const load = async () => {
      setState(prev => ({ ...prev, loading: true }))

      try {
        const device = await getDevice(id)
        setState(prev => ({
          ...prev,
          loading: false,
          device
        }))
      } catch (err) {
        setState(prev => ({
          ...prev,
          overview: {
            loading: false,
            error: (err as Error).message
          }
        }))
      }
    }
    load()
  }, [])

  useEffect(() => {
    // Set active tab from url
    tabs.map((tab: string, index: number) => {
      if (history.location.pathname.indexOf(tab) !== -1) {
        setActiveTab(index)
      }
    })
  }, [history.location.pathname])

  const handleActiveTab = (event: React.ChangeEvent<{}>, value: number) => {
    setActiveTab(value)
  }

  const generatePassword = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (device) {
      try {
        const credentials = await generateCredentials(
          device.properties.IMEI as string
        )

        setState(prev => ({
          ...prev,
          credentials
        }))
        setOpenCredentials(true)
      } catch (err) {
        setState(prev => ({
          ...prev,
          error: (err as Error).message
        }))
      }
    }
  }

  const regeneratePassword = async (id: string) => {
    if (device) {
      if (id !== device.id) {
        setState(prev => ({
          ...prev,
          error: 'Wrong Device ID'
        }))
      } else {
        try {
          const credentials = await generateCredentials(
            device.properties.IMEI as string
          )

          setState(prev => ({
            ...prev,
            credentials
          }))
          closeRegenerate()
          setOpenCredentials(true)
        } catch (err) {
          setState(prev => ({
            ...prev,
            error: (err as Error).message
          }))
        }
      }
    }
  }

  const handleResetDevice = async () => {
    if (device) {
      setUpdating(true)
      try {
        const updatedDevice = await resetDevice(device.id)
        setState(prev => ({
          ...prev,
          device: updatedDevice
        }))
        snackbar.showMessage(`Device ${id} has been reset`)
      } catch (err) {
        setUpdating(false)
        setState(prev => ({
          ...prev,
          error: (err as Error).message
        }))
      }
    }
  }

  const success = async () => {
    closeOpenCredentials()
    try {
      const device = await getDevice(id)

      setState(prev => ({
        ...prev,
        loading: false,
        device
      }))
    } catch (err) {
      setState(prev => ({
        ...prev,
        overview: {
          loading: false,
          error: (err as Error).message
        }
      }))
    }
  }

  const closeOpenCredentials = () => setOpenCredentials(false)

  const closeRegenerate = () => setOpenRegenerate(false)

  const handleOpenEdit = () => setOpenEdit(true)

  const handleCloseEdit = () => setOpenEdit(false)

  const handleOpenDelete = () => setOpenDelete(true)

  const handleCloseDelete = () => setOpenDelete(false)

  const handleEdit = async (serial: string, group: string) => {
    setUpdating(true)
    try {
      const updatedDevice = await updateDevice(id, {
        serialNumber: serial,
        groupId: group
      })
      setState(prev => ({
        ...prev,
        device: updatedDevice
      }))
      handleCloseEdit()
      snackbar.showMessage(`Device ${id} updated`)
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: (err as Error).message
      }))
    }
    setUpdating(false)
  }

  const handleDelete = async () => {
    setDeleting(true)
    try {
      await deleteDevice(id)
      snackbar.showMessage(`Device ${id} deleted`)
      history.push('/devices')
    } catch (err) {
      setDeleting(false)
      setState(prev => ({
        ...prev,
        error: (err as Error).message
      }))
    }
    handleCloseDelete()
  }

  const handleSuspendDevice = async () => {
    if (device && device?.deviceType === 'motorcycle') {
      try {
        const func =
          device.properties.Banned === 'true' ? unbanDevice : banDevice
        await func(device.id)
        const updatedDevice = await getDevice(id)
        setState(prev => ({
          ...prev,
          device: updatedDevice
        }))
        snackbar.showMessage(
          `Device ${id} ${
            // @ts-ignore
            updatedDevice.properties.Banned ? 'suspended' : 'activated'
          }`
        )
      } catch (err) {
        const error = err as Error
        setState(prev => ({
          ...prev,
          error: error.message
        }))
      }
    }
  }

  const menu = [
    {
      label: 'Edit device',
      action: handleOpenEdit
    },
    {
      label: 'Regenerate password',
      action: () => setOpenRegenerate(true),
      disabled: !device?.credentialsProvided
    },
    {
      label: `${
        device?.deviceType === 'motorcycle' &&
        device.properties.Banned === 'true'
          ? 'Activate'
          : 'Suspend'
      } device`,
      disabled: device?.deviceType !== 'motorcycle',
      action: handleSuspendDevice
    },
    {
      label: 'Reset device',
      action: handleResetDevice
    },
    {
      label: 'Delete device',
      action: handleOpenDelete
    }
  ]

  if (!loading && error) {
    return <Error legend={error} />
  }

  return (
    <>
      <div className={classes.wrapper}>
        <CustomToolbar
          header={device ? device.serialNumber : '--'}
          subheader={
            device ? (device.connected ? 'CONNECTED' : 'DISCONNECTED') : '--'
          }
          caption={
            device ? (device.deviceType === 'truck' ? 'Trailer' : 'Ride') : '--'
          }
          icon={<DevicesIcon />}
        >
          <div>
            <Tooltip
              title={
                !device?.credentialsProvided
                  ? 'Get password'
                  : 'Password obtained'
              }
            >
              <IconButton
                aria-label="get-password"
                onClick={generatePassword}
                disabled={device?.credentialsProvided}
              >
                {device?.credentialsProvided ? (
                  <LockOutlinedIcon color="primary" />
                ) : (
                  <LockOpenIcon color="primary" />
                )}
              </IconButton>
            </Tooltip>
            <ThreeDotMenu menu={menu} />
          </div>
        </CustomToolbar>

        <Tabs
          value={activeTab}
          onChange={handleActiveTab}
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
        >
          <Tab label="Overview" component={Link} to={`/devices/${id}`} />
          <Tab
            label="Messages"
            component={Link}
            to={`/devices/${id}/messages`}
          />
          <Tab label="Logs" component={Link} to={`/devices/${id}/logs`} />
          <Tab label="Reports" component={Link} to={`/devices/${id}/reports`} />
          <Tab label="Alerts" component={Link} to={`/devices/${id}/alerts`} />
        </Tabs>

        <Grid container spacing={3} className={classes.grid}>
          <Route
            exact
            path="/devices/:id/messages"
            component={() => (
              <DeviceMessages loading={loading} device={device} />
            )}
          />
          <Route
            exact
            path="/devices/:id/logs"
            component={() => <DeviceLogs />}
          />
          <Route
            exact
            path="/devices/:id/reports"
            component={() => <DeviceReports />}
          />
          <Route
            exact
            path="/devices/:id/alerts"
            component={() => <DeviceAlerts />}
          />
          <Route
            exact
            path="/devices/:id"
            component={() => (
              <DeviceOverview loading={loading} error={error} device={device} />
            )}
          />
        </Grid>
      </div>
      <Modal
        title="YOUR DEVICE PASSWORD"
        open={openCredentials}
        onClose={closeOpenCredentials}
      >
        <Credentials credentials={credentials} onClose={success} />
      </Modal>
      <Modal
        title="REGENERATE DEVICE PASSWORD"
        open={openRegenerate}
        onClose={closeRegenerate}
      >
        <Regenerate
          onRegenerate={regeneratePassword}
          onClose={closeRegenerate}
        />
      </Modal>

      <Modal title="EDIT DEVICE" open={openEdit} onClose={handleCloseEdit}>
        <EditDevice
          creationError={error}
          loading={updating}
          serial={device?.serialNumber}
          group={device?.groupId}
          onClose={handleCloseEdit}
          onSubmit={handleEdit}
        />
      </Modal>

      <Modal
        title="DELETE DEVICE"
        open={openDelete}
        onClose={handleCloseDelete}
      >
        <Confirm
          onNo={handleCloseDelete}
          onYes={handleDelete}
          submiting={deleting}
          submitingText="Deleting device"
        />
      </Modal>
    </>
  )
}

export default Device
