import React, { useState, useEffect } from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import FormControl from '@material-ui/core/FormControl'
import { DateTimePicker } from '@material-ui/pickers'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import CheckIcon from '@material-ui/icons/Check'

import { useStyles } from './styles'
import IconButton from '@material-ui/core/IconButton'

interface Props {
  from: string
  to: string
  onChangeDate: (from: string, to: string) => void
}

const ReportsToolbar: React.FunctionComponent<Props> = props => {
  const classes = useStyles()
  const minDate = moment().subtract(30, 'day')
  const maxDate = moment().add(1, 'hour')
  const [disabled, setDisabled] = useState<boolean>(false)

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    props.onChangeDate(props.from, props.to)
  }

  useEffect(() => {
    const isAfter = !moment(props.from).isBefore(props.to)
    setDisabled(isAfter)
  }, [props.from, props.to])

  return (
    <Toolbar className={classes.toolbar}>
      <Typography variant="h5">REPORTS</Typography>
      <div className={classes.toolbarWrapper}>
        <FormControl className={classes.typeSelect}>
          <DateTimePicker
            id="from"
            variant="inline"
            label="From"
            value={props.from}
            minDate={minDate}
            maxDate={maxDate}
            onChange={date => props.onChangeDate(date!.toISOString(), props.to)}
          />
        </FormControl>
        <FormControl className={classes.typeSelect}>
          <DateTimePicker
            id="to"
            variant="inline"
            label="To"
            value={props.to}
            minDate={minDate}
            maxDate={maxDate}
            onChange={date =>
              props.onChangeDate(props.from, date!.toISOString())
            }
          />
        </FormControl>
        <IconButton color="primary" onClick={handleSubmit} disabled={disabled}>
          <CheckIcon />
        </IconButton>
      </div>
    </Toolbar>
  )
}

export default ReportsToolbar
