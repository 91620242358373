import React from 'react'

import { Switch } from 'react-router-dom'
import UsersList from './UsersList'
import UsersDetails from './UsersDetails'
import RouteWithTitle from '../../components/RouteWithTitle'

const Users: React.FunctionComponent = () => {
  return (
    <Switch>
      <RouteWithTitle
        title="User details"
        exact
        path="/users/:id"
        component={UsersDetails}
      />
      <RouteWithTitle
        title="Users list"
        exact
        path="/users"
        component={UsersList}
      />
    </Switch>
  )
}

export default Users
