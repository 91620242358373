import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    height: '100%',
    padding: theme.spacing(6)
  },
  header: {
    minHeight: '40vh',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column'
  },
  icon: {
    fontSize: '5rem',
    textAlign: 'center',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    alignSelf: 'center'
  },
  headerText: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightLight
  },
  actions: {
    borderTop: theme.palette.primary.main
  }
}))
