import React from 'react'
import { Link } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { useStyles } from '../styles'

interface Props {
  users: User[]
}

const UserGrid: React.FunctionComponent<Props> = ({ users }) => {
  const classes = useStyles()

  return (
    <Paper square>
      <div className={classes.userTitle}>
        <Typography>
          <strong>EMAIL</strong>
        </Typography>
        <Typography>
          <strong>NAME</strong>
        </Typography>
      </div>

      {users.map(user => (
        <div key={user.id} className={classes.userGrid}>
          <Link to={`/users/${user.id}`}>
            <Typography>{user.email}</Typography>
          </Link>

          <Typography>{user.name}</Typography>
        </div>
      ))}
    </Paper>
  )
}

export default UserGrid
