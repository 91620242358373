import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Moment from 'react-moment'
import JSONPretty from 'react-json-pretty'
import { useStyles } from './styles'
import './styles.scss'

interface Props {
  message: MqttMessage
}

const MessageItem: React.FunctionComponent<Props> = props => {
  const classes = useStyles()

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        <>
          <div className={classes.column}>
            <Typography className={classes.heading} noWrap>
              <Moment format="MM/DD/YYYY HH:mm:ss">
                {props.message.timestamp}
              </Moment>
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.headingBold} noWrap>
              {props.message.channel}
            </Typography>
          </div>
        </>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <JSONPretty id="json-pretty" data={props.message.message}></JSONPretty>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default MessageItem
