import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DownloadIcon from '@material-ui/icons/CloudDownload'

import { downloadFile } from '../../../lib/util'
import { useStyles } from './styles'

interface Props {
  credentials: DeviceCredentials | null
  onClose: () => Promise<void>
}

const Credentials: React.FunctionComponent<Props> = props => {
  const classes = useStyles()

  const downloadCredentials = (e: React.SyntheticEvent) => {
    e.preventDefault()
    downloadFile(JSON.stringify(props.credentials), 'mqttCredentials.json')
    props.onClose()
  }

  return (
    props.credentials && (
      <Grid container direction="column">
        <Typography variant="body2">Your device's password</Typography>
        <div className={classes.passwordContianer}>
          <div className={classes.password}>
            <Typography variant="body2" className={classes.password}>
              {props.credentials.secret}
            </Typography>
          </div>
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={downloadCredentials}
          >
            Download
          </Button>
        </div>
        <Typography component="span" className={classes.textPanel}>
          <Box
            component="p"
            color="palette.primary"
            m={0}
            fontSize="body2.fontSize"
            fontWeight="fontWeightBold"
            fontStyle="italic"
            className={classes.important}
          >
            Copy this somewhere safe!
          </Box>
          <Box fontSize="body2.fontSize" fontStyle="italic">
            For security, it is not possible to retrieve this password again.
          </Box>
          <Box fontSize="body2.fontSize" fontStyle="italic">
            It will only be possible to regenerate a new password, which will
            wipe this one out.
          </Box>
        </Typography>
        <div className={classes.buttonContainer}>
          <Button variant="contained" color="primary" onClick={props.onClose}>
            Ok
          </Button>
        </div>
      </Grid>
    )
  )
}

export default Credentials
