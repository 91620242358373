import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { downloadFile } from '../../../lib/util'
import { apiPost } from '../../../actions'
import Error from '../../../components/Error'
import { useStyles } from './styles'

interface Props {
  onClose: () => void
  devices: DeviceData[]
}

interface State {
  loading: boolean
  error: string
}

const BatchCredentials: React.FunctionComponent<Props> = props => {
  const [state, setState] = useState<State>({
    loading: false,
    error: ''
  })
  const { loading, error } = state
  const classes = useStyles()

  const generateCredentials = async () => {
    setState(prev => ({
      ...prev,
      loading: true
    }))
    const imeis = props.devices.map(device => device.properties.IMEI)

    try {
      const { credentials } = await apiPost<DeviceCredentialsResponse>(
        'devices/bulk-credentials',
        {
          imeis
        }
      )
      downloadFile(JSON.stringify(credentials), 'mqttCredentials.json')
      props.onClose()
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: err.message
      }))
    }
  }

  if (!loading && error) {
    return <Error legend={error} />
  }

  return (
    <Grid container direction="row" className={classes.container}>
      <Typography variant="body1">
        To generate and download credentials for the created devices, click the
        button bellow.
      </Typography>
      <div className={classes.center}>
        <Button
          variant="outlined"
          onClick={generateCredentials}
          disabled={loading}
        >
          {loading ? 'Generating' : 'Get'} Credentials
        </Button>
      </div>
      <div className={classes.buttonPanel}>
        <Button variant="contained" color="primary" onClick={props.onClose}>
          OK
        </Button>
      </div>
    </Grid>
  )
}

export default BatchCredentials
