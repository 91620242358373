import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import CssBaseline from '@material-ui/core/CssBaseline'
import Avatar from '@material-ui/core/Avatar'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import logo from '../../../media/images/logo-red.svg'
import SignIn from './SignIn'
import ForgotPassword from './ForgotPassword'
import { useStyles } from './styles'

const Copyright: React.FunctionComponent = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://www.aritronix.com/">
      Aritronix
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
)

const Login: React.FunctionComponent = () => {
  const [state, setState] = useState({ showResetPassword: false })
  const classes = useStyles()

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7}>
        <div className={classes.container}>
          <img src={logo} alt="Aritronix" className={classes.logo} />
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.container}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {state.showResetPassword ? <ForgotPassword /> : <SignIn />}
          <Grid container>
            <Link
              variant="body1"
              className={classes.link}
              onClick={() =>
                setState(prev => ({
                  ...prev,
                  showResetPassword: !state.showResetPassword
                }))
              }
            >
              {state.showResetPassword ? 'Cancel' : 'Forgot password?'}
            </Link>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default Login
