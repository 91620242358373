import React, { useState } from 'react'
import FormEdition from '../../../components/Form/FormEdition'

interface Props {
  user: User
  onSave: (
    updatedProperties: Partial<UserProperties>,
    callback: () => void
  ) => Promise<void>
  saving: boolean
}

const UsersProperties: React.FunctionComponent<Props> = props => {
  const [updatedProperties, setUpdatedProperties] = useState<
    Partial<UserProperties>
  >({})

  const handleUpdateProperties = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setUpdatedProperties(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleOnSave = (callback: () => void) => {
    return props.onSave(updatedProperties, callback)
  }

  return (
    <FormEdition
      title="Custom fields"
      id={props.user.id}
      fields={props.user.properties}
      fieldsProperties={{}}
      onSave={handleOnSave}
      onFieldUpdate={handleUpdateProperties}
      saving={props.saving}
      savingError={''}
    />
  )
}

export default UsersProperties
