import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import { useStyles } from './styles'
import { capitalize } from 'lodash'

interface Props {
  group: Group
}

const Details: React.FunctionComponent<Props> = ({ group }) => {
  const classes = useStyles()

  return (
    <Paper square className={classes.details}>
      <Typography variant="subtitle2">Created</Typography>
      <Typography>{moment(group.createdAt).fromNow()}</Typography>
      <Typography variant="subtitle2">Last Modified</Typography>
      <Typography>{moment(group.updatedAt).fromNow()}</Typography>
      <Typography variant="subtitle2">ID</Typography>
      <Typography>{group.id}</Typography>
      <Typography variant="subtitle2">Group Type</Typography>
      <Typography>{capitalize(group.role)}</Typography>
    </Paper>
  )
}

export default Details
