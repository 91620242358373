import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import DevicesIcon from '@material-ui/icons/Devices'

import Modal from '../../components/Modal'
import DevicesList from './DevicesList'
import CreateDevice from './CreateDevice'
import BatchCredentials from './CreateDevice/BatchCredentials'
import CustomToolbar from '../../components/CustomToolbar'

const Devices: React.FunctionComponent = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openCredentials, setOpenCredentials] = useState<boolean>(false)
  const [createdDevices, setCreatedDevices] = useState<DeviceData[]>([])

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  const handleOpenCredentials = (devices: DeviceData[]) => {
    setCreatedDevices(devices)
    setOpenCredentials(true)
  }

  const handleCloseCredentials = () => setOpenCredentials(false)

  return (
    <>
      <CustomToolbar header="Devices" icon={<DevicesIcon />}>
        <Tooltip title="Add new device">
          <IconButton
            color="primary"
            aria-label="add new device"
            onClick={handleOpenModal}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </CustomToolbar>

      <Route exact path="/devices">
        <DevicesList />
      </Route>

      <Modal
        title="CREATE NEW DEVICE"
        open={openModal}
        onClose={handleCloseModal}
      >
        <CreateDevice
          onClose={handleCloseModal}
          onOpenCredentials={handleOpenCredentials}
        />
      </Modal>
      <Modal
        title="GENERATE DEVICES CREDENTIALS"
        open={openCredentials}
        onClose={handleCloseCredentials}
      >
        <BatchCredentials
          onClose={handleCloseCredentials}
          devices={createdDevices}
        />
      </Modal>
    </>
  )
}

export default Devices
