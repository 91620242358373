import React, { useState } from 'react'
import FormCreate from '../../../components/Form/FormCreate'
import { getUserRoles } from '../../../lib/util'
import { useSnackbar } from 'material-ui-snackbar-provider'
import { apiPost, apiGet } from '../../../actions'

interface Props {
  onClose: () => void
  forAdmin?: boolean
}

const UsersCreate: React.FunctionComponent<Props> = props => {
  const snackbar = useSnackbar()

  const [newUser, setNewUser] = useState<Partial<User>>({})
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isSavingError, setIsSavingError] = useState<string | null>(null)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<Partial<Group>[] | null>(
    null
  )
  const [isSearchError, setIsSearchError] = useState<string | null>(null)

  const handleUpdateUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (name && value) {
      setNewUser(prev => ({
        ...prev,
        [name]: value
      }))
    } else {
      // MenuItem renders data attributes in child. In this case name and value are undefined
      const key = e.currentTarget.firstElementChild?.getAttribute('data-key'),
        value = e.currentTarget.firstElementChild?.getAttribute('data-value')

      if (key && value) {
        setNewUser(prev => ({
          ...prev,
          [key]: value
        }))
      }
    }
  }

  const handleOnSave = async () => {
    setIsSaving(true)
    try {
      await apiPost<UserResponse>(
        `users`,
        props.forAdmin ? { ...newUser, role: 'admin' } : newUser
      )
      snackbar.showMessage('User created.')
      props.onClose()
    } catch (error) {
      setIsSavingError(error.message)
    }

    setIsSaving(false)
  }

  const handleSearchGroups = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value

    setIsSearching(true)

    try {
      const { groups } = await apiGet<GroupsResponse>(
        `groups?name=${searchTerm}&pageSize=10000`
      )

      const results = groups.results.map((group: Group) => ({
        name: group.name,
        id: group.id
      }))

      setSearchResults(results)
    } catch (error) {
      setIsSearchError(error.message)
    }

    setIsSearching(false)
  }

  const fields = {
    name: { htmlType: 'text' },
    email: { htmlType: 'email' },
    ...(!props.forAdmin && {
      role: {
        type: 'select',
        options: getUserRoles().filter(role => role !== 'admin')
      },
      groupId: {
        type: 'select-search',
        onSearch: handleSearchGroups,
        options: searchResults,
        searching: isSearching
      }
    }),
    password: {
      htmlType: 'password'
    }
  }

  return (
    <FormCreate
      fields={fields}
      onFieldUpdate={handleUpdateUser}
      onSave={handleOnSave}
      saving={isSaving}
      savingError={isSavingError || isSearchError}
    />
  )
}

export default UsersCreate
