import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../../context/auth'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { authTokens, setAuthTokens } = useAuth()

  useEffect(() => {
    if (authTokens) {
      const now = new Date().getTime()
      if (authTokens.exp < (now + 1) / 1000) {
        setAuthTokens()
      }
    }
  }, [])

  return (
    <Route
      {...rest}
      render={props =>
        authTokens ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  )
}

export default PrivateRoute
