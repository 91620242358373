export const apiCall = async <T>(path: string, options = {}) => {
  try {
    let payload
    const res = await fetch(`${process.env.API_URL}/${path}`, options)

    try {
      payload = await res.json()
    } catch {
      payload = null
    }

    if (!res.ok) {
      const { message } = payload
      throw new Error(message)
    }

    return payload as T
  } catch (err) {
    throw err
  }
}

export const apiGet = async <T>(path: string) => {
  return apiCall<T>(path, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json'
    }
  })
}

export const apiPost = async <T>(path: string, body: Record<string, any>) => {
  return apiCall<T>(path, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
}

export const apiPut = async <T>(path: string, body: Record<string, any>) => {
  return apiCall<T>(path, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
}

export const apiDelete = async <T>(path: string) => {
  return apiCall<T>(path, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json'
    }
  })
}

export const login = async (credentials: UserCredentials) => {
  return apiCall(`users/auth`, {
    method: 'POST',
    body: JSON.stringify(credentials)
  })
}
