import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useStyles } from './styles'

interface Props {
  header?: string | null
  subheader?: string | null
  caption?: string | null
  icon?: React.ClassicElement<{}>
}

const CustomToolbar: React.FunctionComponent<Props> = props => {
  const classes = useStyles()

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.toolbarWrapper}>
        {props.icon && props.icon}
        <div className={classes.toolbarHeader}>
          <Typography component="div">
            {props.subheader && (
              <Box fontSize={12} m={1} fontWeight="fontWeightLight">
                {props.subheader}
              </Box>
            )}
            <Box fontSize="h5.fontSize" m={1} fontWeight="fontWeightMedium">
              {props.header}
            </Box>
            {props.caption && (
              <Box fontSize="fontSize" m={1} fontWeight="fontWeightLight">
                {props.caption}
              </Box>
            )}
          </Typography>
        </div>
      </div>
      {props.children && (
        <div className={classes.buttonContainer}>{props.children}</div>
      )}
    </Toolbar>
  )
}

export default CustomToolbar
