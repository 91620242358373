import mqtt, { MqttClient, PacketCallback } from 'mqtt'
import { LocalStorage } from '../constants'

const getClient = (): MqttClient => {
  const password = localStorage.getItem(LocalStorage.JWT) || ''
  const storageUser = localStorage.getItem(LocalStorage.USER)
  const user = storageUser ? JSON.parse(storageUser) : null
  const clientId = user.email || ''

  const client = mqtt.connect(process.env.BROKER_URL, {
    username: 'Auth:JWT',
    password,
    clientId,
    clean: true,
    keepalive: 120
  })

  return client
}

const subscribeToAll = (client: MqttClient, id: string) => {
  client.subscribe(
    [`${id}/incoming`, `${id}/outgoing`, `${id}/_updates/fields`],
    (err, granted) => {
      if (err) {
        console.error({ err }, 'MQTT: Unable to subscribe to topics')
        return
      }
      console.log(
        { topics: granted.map(entry => entry.topic) },
        'MQTT: subscribed to topics'
      )
    }
  )
}

const subscribeToQueclinkIncoming = (client: MqttClient, imei: string) => {
  client.subscribe([`queclink/incoming/${imei}`], (err, granted) => {
    if (err) {
      console.error({ err }, 'MQTT: Unable to subscribe to topics')
      return
    }
    console.log(
      { topics: granted.map(entry => entry.topic) },
      'MQTT: subscribed to topics'
    )
  })
}

const onMessage = (client: MqttClient, callback: any) => {
  client.on('message', (topic, message) => {
    callback(topic, message.toString())
  })
}

const publish = (
  client: MqttClient,
  topic: string,
  message: Record<string, any> | string,
  callback?: PacketCallback
) => {
  const payload =
    typeof message === 'object' ? JSON.stringify(message) : message
  client.publish(topic, payload, callback)
}

const closeConnection = (client: MqttClient, id: string) => {
  client.unsubscribe(
    [`${id}/incoming`, `${id}/outgoing`, `${id}/_updates/fields`],
    {},
    () => client.end()
  )
}

export const mqttService = {
  getClient,
  subscribeToAll,
  subscribeToQueclinkIncoming,
  onMessage,
  publish,
  closeConnection
}
