import React, { useState } from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { useStyles } from './styles'
import Paper from '@material-ui/core/Paper'

interface Props {
  handleChannelFilter: any
  connected: boolean
}

const channelsFilter = [
  'all channels',
  '/incoming',
  '/outgoing',
  '/_updates/fields'
]

const MessagesToolbar: React.FunctionComponent<Props> = props => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [selectedChannel, setSelectedChannel] = useState('all channels')

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Paper square>
      <Toolbar className={classes.toolbar}>
        <div>
          <Typography variant="h5">SHOWING</Typography>
          <Typography
            variant="subtitle2"
            color={props.connected ? 'secondary' : 'primary'}
          >
            Connect{props.connected ? 'ed' : 'ting'} to broker
          </Typography>
        </div>

        <FormControl className={classes.typeSelect}>
          <InputLabel id="demo-customized-select-label">Channel</InputLabel>
          <Select
            id="message-filter-channel"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={selectedChannel}
            onChange={event => {
              const {
                target: { value }
              } = event
              props.handleChannelFilter(value)
              setSelectedChannel(value as string)
            }}
          >
            {channelsFilter.map((channel: string, index: number) => (
              <MenuItem value={channel} key={index}>
                {channel}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Toolbar>
    </Paper>
  )
}

export default MessagesToolbar
