import React, { useState, useEffect } from 'react'
import CustomToolbar from '../../../components/CustomToolbar'
import Container from '@material-ui/core/Container'
import { useStyles } from './styles'
import { apiGet } from '../../../actions'
import Spinner from '../../../components/Spinner'
import Error from '../../../components/Error'
import Map from '../../../components/Map'
import MarkerClusterer from '@google/markerclustererplus'
import ReactDOMServer from 'react-dom/server'
import { ServerStyleSheets } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import DetailsField from '../../../components/DetailsField'
import Paper from '@material-ui/core/Paper'

interface LocationResponse {
  devices: {
    results: Record<string, [number, number]>[]
    meta: Meta
  }
}

interface DeviceLocation {
  id: string
  status: boolean
  position: google.maps.LatLng
  serial: string
}

const DevicesLocation: React.FunctionComponent = () => {
  const [devices, setDevices] = useState<Record<string, [number, number]>[]>([])
  const [count, setCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const classes = useStyles()

  const fetchDevices = async () => {
    setIsLoading(true)
    try {
      const { devices } = await apiGet<LocationResponse>(`devices/locations`)
      setDevices(devices.results)
      setCount(devices.meta.count)
    } catch (error) {
      setError(error.message)
    }
    setIsLoading(false)
  }

  const parseDeviceLocation = (
    device: [string, Record<string, any>]
  ): DeviceLocation => {
    return {
      id: device[0],
      status: device[1].cn,
      position: new window.google.maps.LatLng(device[1].lat, device[1].lng),
      serial: device[1].sn
    }
  }

  const renderInfoWindow = (device: DeviceLocation) => {
    const sheets = new ServerStyleSheets()

    return ReactDOMServer.renderToString(
      sheets.collect(
        <Card>
          <CardContent>
            <DetailsField label="ID" value={device.id} />
            <DetailsField label="Serial" value={device.serial} />
            <DetailsField
              label="STATUS"
              value={device.status ? 'Online' : 'Offline'}
            />
          </CardContent>
        </Card>
      )
    )
  }

  const createMarkers = () => (map: google.maps.Map) => {
    const infowindow = new window.google.maps.InfoWindow()

    const markers = Object.entries(devices).map(
      (device: [string, Record<string, any>]) => {
        const _device: DeviceLocation = parseDeviceLocation(device)

        const marker = new window.google.maps.Marker({
          position: _device.position
        })

        google.maps.event.addListener(
          marker,
          'click',
          (marker => {
            return () => {
              infowindow.setContent(renderInfoWindow(_device))
              infowindow.open(map, marker)
            }
          })(marker)
        )

        return marker
      }
    )

    return new MarkerClusterer(map, markers, {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    })
  }

  const mapProps = {
    options: {
      center: { lat: 21, lng: -15 },
      zoom: 2
    },
    onMount: createMarkers()
  }

  useEffect(() => {
    fetchDevices()
  }, [])

  if (isLoading) {
    return <Spinner legend="Loading locations..." />
  }

  if (!isLoading && error) {
    return <Error legend={`${error}.`} />
  }

  return (
    <>
      <CustomToolbar
        header="Where are my devices located?"
        subheader="DEVICES LOCATION"
        caption={`${count} devices reporting location`}
      />
      <Container className={classes.container} maxWidth={false}>
        <Paper square className={classes.paper}>
          <Map {...mapProps} />
        </Paper>
      </Container>
    </>
  )
}

export default DevicesLocation
