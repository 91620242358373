import { apiGet, apiPut, apiDelete } from '../../actions'

export const getGroup = async (id: string) => {
  const { group } = await apiGet<GroupResponse>(`groups/${id}`)

  return group
}

export const getChildren = async (id: string, page: number) => {
  const { groups } = await apiGet<GroupsResponse>(
    `groups?count=devices,end-users,children&parentId=${id}&page=${page}&pageSize=9&sortBy=name`
  )

  return groups
}

export const getUsers = async (id: string, page: number) => {
  const { users } = await apiGet<UsersResponse>(
    `users?groupId=${id}&page=${page}&pageSize=3`
  )

  return users
}

export const getDevices = async (id: string, page: number) => {
  const { devices } = await apiGet<DevicesResponse>(
    `devices?groupId=${id}&page=${page}&pageSize=3`
  )

  return devices
}

export const updateGroup = async (
  id: string,
  name: string,
  parentId: string | null
) => {
  const { group } = await apiPut<GroupResponse>(`groups/${id}`, {
    name,
    parentId
  })

  return group
}

export const deleteGroup = async (id: string) => {
  return apiDelete(`groups/${id}`)
}
