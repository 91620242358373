import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { useStyles } from './styles'
import DialogActions from '@material-ui/core/DialogActions'
import Alert from '@material-ui/lab/Alert'

interface Props {
  onRegenerate: (id: string) => Promise<void>
  onClose: () => void
}

const Regenerate: React.FunctionComponent<Props> = props => {
  const [id, setId] = useState<string>('')
  const classes = useStyles()

  const handleRenegerate = (e: React.SyntheticEvent) => {
    e.preventDefault()
    props.onRegenerate(id)
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setId(value)
  }

  return (
    <Grid container direction="column">
      <Alert severity="warning">
        Generating a new password for this device will wipe out the previous
        password. The device will stop working until it has the new password.
      </Alert>

      <TextField
        id="id"
        name="id"
        label="Device ID"
        variant="outlined"
        className={classes.input}
        onChange={onInputChange}
        required
      />
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRenegerate}
          disabled={id === ''}
        >
          Regenerate
        </Button>
      </DialogActions>
    </Grid>
  )
}

export default Regenerate
